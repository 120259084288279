import isHandlerEnabled from './interceptor.checker';
import {getUserStorage} from '../projectStorage'
const authenticationInterceptorHandler = (request) => {
    if (isHandlerEnabled(request)) {
        const userStorage = getUserStorage()
        request.headers.common['Authorization'] = 'Bearer ' +  userStorage.token.access_token;
        request.headers.common['Access-Control-Allow-Origin'] = "*"
    }
    return request;
}

export default authenticationInterceptorHandler;

//TODO: Local store yerine şunu implemente etmek lazım
//https://michaelwashburnjr.com/best-way-to-store-tokens-redux/

// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZGZkcy5jb20iLCJTZXNzaW9uSWQiOiI4YTU3NDhkNS00NjIyLTQyY2EtOWJjOC01ZmQ2MWRmNTRkN2IiLCJBcHBsaWNhdGlvbk5hbWUiOiJTdW1tYXJ5RGVjbGFyYXRpb24iLCJSZWdpb24iOiIiLCJOYW1lU3VybmFtZSI6IlRlc3QiLCJDb21wYW55IjoiREZEUyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiZXhwIjoxNjA3MDg5MjE1LCJpc3MiOiJkZmRzLmNvbSIsImF1ZCI6ImRmZHMuY29tIn0.63vXTcfwRpMKOESnC4-4kIRQQ0dCeftKiTnfQvtmtnM"