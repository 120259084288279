import React from 'react'
import { Modal } from '@dfds-ui/modal'
import { Button } from '@dfds-ui/react-components';
import './YesNoModal.scss';
const YesNoModal = (props) => {
    const { OpenModal, OnClose, Title, onYesClick, onNoClick, Message, yesButtonComp } = props;
    return (
        <Modal
            className="YesNoModal"
            isOpen={OpenModal}
            onRequestClose={OnClose}
            heading={<span className="YesNoModalTitle">
                {Title}</span>}
            headerClassName="headerYesOrNo">
            <p id="YesNoMessage">{Message}</p>
            {
                
                !yesButtonComp ?
                <Button
                    className="YesButton"
                    onClick={onYesClick}
                    variation="secondary"
                    iconAlign="left">
                    Yes
                </Button> : 
                yesButtonComp
            }
            <Button className="NoButton"
                onClick={onNoClick ? onNoClick : OnClose}
                variation="secondary"
                iconAlign="left">
                No
            </Button>
        </Modal>
    );
}
export default YesNoModal;
