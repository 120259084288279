import React from 'react';
import Declaration from '../component/Declaration/Declaration'
import { connect } from 'react-redux';
import {pageChanged} from '../store/action/pageActions'
//Dummy for now just routing
const declaration = (props) => {
    const {pageName,pageChanged,match} = props;
    if(pageName !== match.path)
    {
        pageChanged(match.path)
    }
    return <Declaration />
}
const mapStateToProps = (state) => {
    return {
        pageName : state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(declaration);
