import React from 'react'
import RowCell from './RowCell/RowCell'
import './JustRow.scss'
import PropTypes from 'prop-types';
import { getDataFromParent } from '../../Helper/ObjectTree'
export default class TableRow extends React.Component {
    constructor(props) {
        super(props)
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.RowData !== this.props.RowData) {
            return true;
        }
        if (nextProps.DataDesign !== this.props.DataDesign) {
            return true
        }
        return false;
    }
    // componentDidMount() {
    //     const rowData = this.props.RowData
    //     const rowIndex = this.props.RowIndex
    //     const _onRowClick = this.props.onRowClick
    //     if (_onRowClick && typeof _onRowClick === "function" && rowIndex === 0 && this.props.RadioButton) {
    //         // _onRowClick(nul  l, rowData)
    //     }
    // }
    render() {
        const dataDesigns = this.props.DataDesign
        const rowData = this.props.RowData
        const columnDesigns = this.props.ColumnDesigns
        const rowIndex = this.props.RowIndex
        const _onRowClick = this.props.onRowClick
        if (typeof rowData !== "object") return <></>
        return (
            <tr
                onClick={(e) => {
                    _onRowClick(e, rowData);
                    const radioButton = document.getElementById(`${rowIndex}_radioButton`);
                    radioButton.checked = true
                }}
                className={"JustStaticTable-TableRow " + this.props.className}>
                {
                    dataDesigns.map((design, key) => {
                        if (design.hide) return <td style={{ display: "none" }} key={key} />
                        const columnDesign = columnDesigns[key]
                        const parentIds = columnDesign.parentIds
                        return <RowCell
                            RowIndex={rowIndex}
                            ColumnDesigns={columnDesigns}
                            DesignIndex={key}
                            key={key}
                            CellData={getDataFromParent(parentIds, rowData, design.columnId, columnDesigns[key].parentArrayPosition)}
                            RowData={rowData}
                            DataDesign={design}
                            onRowClick={_onRowClick}
                        />
                    })
                }
            </tr>
        )
    }
}
TableRow.propTypes = {
    DataDesign: PropTypes.array,
    RowData: PropTypes.object,
    ColumnDesigns: PropTypes.array,
    rowIndex: PropTypes.number,
    onRowClick: PropTypes.func,
}
TableRow.defaultProps = {
    DataDesign: [],
    RowData: {},
    ColumnDesigns: [],
    rowIndex: 0,
    onRowClick: () => { }
}