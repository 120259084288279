import React, { useState, useEffect } from 'react';
import DateInput from '../../CommonUI/DateInput/DateInput'
import SearchButton from '../../CommonUI/DotAnimButton/DotAnimButton'
import { Select } from '@dfds-ui/react-components';
import { Down } from '@dfds-ui/icons';
import { timeRangeValid } from '../../../core/validationHandler'
import OptionMapper from '../../CommonUI/SelectOptionMapper/OptionMapper'
import { connect } from 'react-redux';
import {
    setVoyageError, setVoyageDate, setVoyageArrivalPort,
    setVoyageDeparturePort, setVoyageDirection, getVoyages, getVoyagePorts
} from '../../../store/action/voyageAction'

const VoyageTableHeader = (props) => {
    const { WaitForRequest, ScheduleDateBegin, ScheduleDateEnd, DeparturePort,
        ArrivalPort, Direction, VoyageRequestData, setVoyageError, setVoyageDate, setVoyageArrivalPort
        , setVoyageDeparturePort, setVoyageDirection, getVoyages, getVoyagePorts, VoyagePorts } = props;

    const [DateInputValidation, setDateInputValidation] = useState(true)
    const [Ports,setPorts] = useState(null)
    useEffect(() => {
        getVoyagePorts();
    }, [])
    useEffect(() => {
        let PortOptions = [];
        PortOptions.push({
            Value : "",
            ValueText : "All Ports"
        })
        if (VoyagePorts === null) return
        VoyagePorts.map((Port) => {
            PortOptions.push({
                Value: Port.portCode,
                ValueText: Port.portName
            })
        })
        setPorts(PortOptions)
    }, [VoyagePorts])
    const DirectionOptions = [
        {
            Value: "",
            ValueText: "All"
        },
        {
            Value: "Import",
            ValueText: "Import"
        },
        {
            Value: "Export",
            ValueText: "Export"
        }
    ]
    const DeparturePortOptionObject = {
        Options: Ports,
        DisableOptionValue: ArrivalPort,
        MainOption: {
            Value: "",
            ValueText: "POL"
        },
    }
    const ArrivalPortOptionObject = {
        Options: Ports,
        DisableOptionValue: DeparturePort,
        MainOption: {
            Value: "",
            ValueText: "POD"
        },
    }
    const DirectionOptionObject = {
        Options: DirectionOptions,
        MainOption: {
            Value: "",
            ValueText: "Direction"
        }
    }


    const onSearch = () => {
        const result = timeRangeValid(ScheduleDateBegin, ScheduleDateEnd)
        const DateValidation = result.valid;
        const ValidationMessage = result.errorMessage
        setDateInputValidation(DateValidation)
        if (!DateValidation) {
            setVoyageError({ ErrorTitle: "Input Error", ErrorCode: "", ErrorMessage: ValidationMessage })
            return;
        }
        getVoyages(VoyageRequestData)

    }
    return (
        <div className="voyageTableHeader">
            <DateInput
                ClassName="datePickerContainer"
                InitStart={ScheduleDateBegin}
                InitEnd={ScheduleDateEnd}
                Valid={DateInputValidation}
                OnDateChange={(ScheduleDateBegin, ScheduleDateEnd) => {
                    setVoyageDate(ScheduleDateBegin, ScheduleDateEnd)
                }}
            />
            <Select
                className="departurePort"
                name="select"
                onChange={(event) => {
                    const DeparturePortValue = event.target.value;
                    setVoyageDeparturePort(DeparturePortValue)
                }}
                icon={<Down />}>
                <OptionMapper OptionObject={DeparturePortOptionObject} />

            </Select>

            <Select
                className="arrivalPort"
                name="select"
                onChange={(event) => {
                    const ArrivalPortValue = event.target.value;
                    setVoyageArrivalPort(ArrivalPortValue)
                }}
                icon={<Down />}>
                <OptionMapper OptionObject={ArrivalPortOptionObject} />
            </Select>

            <Select
                className="departurePort"
                name="select"
                onChange={(event) => {
                    const Direction = event.target.value
                    setVoyageDirection(Direction)
                }}
                icon={<Down />}>
                <OptionMapper OptionObject={DirectionOptionObject} />
            </Select>

            <SearchButton
                className="searchBtn"
                ButtonLabel="SEARCH"
                waitForRequest={WaitForRequest}
                onClick={() => onSearch()}
            />
        </div>
    );

}

const mapStateToProps = state => {
    return {
        WaitForRequest: state.voyage.WaitForRequest,
        ScheduleDateBegin: state.voyage.VoyageRequestData.ScheduleDateBegin,
        ScheduleDateEnd: state.voyage.VoyageRequestData.ScheduleDateEnd,
        DeparturePort: state.voyage.VoyageRequestData.LoadingPort,
        ArrivalPort: state.voyage.VoyageRequestData.UnloadingPort,
        Direction: state.voyage.VoyageRequestData.Direction,
        VoyageRequestData: state.voyage.VoyageRequestData,
        VoyagePorts: state.voyage.VoyagePorts
    }
}
export default connect(mapStateToProps,
    {
        setVoyageError, setVoyageDate, setVoyageArrivalPort,
        setVoyageDeparturePort, setVoyageDirection, getVoyages, getVoyagePorts
    })(VoyageTableHeader);