import declarationTypes from '../type/declaration';
const initialState = {
    DeclarationData: null,
    Error: {
        ErrorCode: -1,
        ErrorTitle: "",
        ErrorMessage: "",
        ChangePage: false
    },
    WaitForRequest: false,
    DeclarationNotFound: false,
    ServiceConnection: true,
    VoyageId: "",
    FirstLoad: true,
    RefreshTime : `${("0" + (new Date().getHours()).toString()).slice(-2)}.${("0" + (new Date().getMinutes()).toString()).slice(-2)}`
}
export default (state = initialState, action) => {
    switch (action.type) {
        case declarationTypes.GET_DECLARATIONS:
            return {
                ...state,
                DeclarationData: {
                    ...action.DeclarationData
                },
                WaitForRequest: false,
                DeclarationNotFound: false,
                ServiceConnection: true,
                // FirstLoad : false
            }
        case declarationTypes.CLEAR_DECLARATION:
            return {
                ...state,
                DeclarationData: null,
                Error: {
                    ErrorCode: -1,
                    ErrorMessage: ""
                },
                WaitForRequest: false,
                DeclarationNotFound: false,
                ServiceConnection: true,
            }
        case declarationTypes.SET_FIRST_LOAD:
            return {
                ...state,
                FirstLoad: action.FirstLoad
            }
        case declarationTypes.CLEAR_DECLARATION_ERROR:
            return {
                ...state,
                Error: {
                    ErrorCode: -1,
                    ErrorTitle: "",
                    ErrorMessage: "",
                    ChangePage: false
                }
            }
        case declarationTypes.UPDATE_REFRESH_TIME:
            return {
                ...state,
                RefreshTime: `${("0" + (new Date().getHours()).toString()).slice(-2)}.${("0" + (new Date().getMinutes()).toString()).slice(-2)}`
                // When you want to hours from date it'would be 1 digits 
                //for example 0.2 i convert it to 2 digits (00.02) 0.2 -> 00.02
            }
        case declarationTypes.NO_DECLARATION:
            return {
                ...state,
                DeclarationData: null,
                DeclarationNotFound: true,
                WaitForRequest: false,
                ServiceConnection: true
            }
        case declarationTypes.SET_ERROR:
            return {
                ...state,
                Error: {
                    ErrorCode: action.ErrorCode,
                    ErrorTitle: action.ErrorTitle,
                    ErrorMessage: action.ErrorMessage
                }

            }
        case declarationTypes.DECLARATION_CONNECTION_ERROR:
            return {
                ...state,
                DeclarationData: null,
                WaitForRequest: false,
                DeclarationNotFound: false,
                ServiceConnection: false,
                Error: {
                    ErrorCode: action.ErrorCode,
                    ErrorTitle: action.ErrorTitle,
                    ErrorMessage: action.ErrorMessage,
                    ChangePage: true
                }
            }
        case declarationTypes.GETTING_DECLARATIONS:
            return {
                ...state,
                WaitForRequest: true,
                DeclarationNotFound: false,
                ServiceConnection: true
            }
        case declarationTypes.SET_REQUEST_VOYAGE_ID:
            return {
                ...state,
                VoyageId: action.VoyageId
            }

        default:
            return state;

    }
}

