const getDateStr = (date = new Date()) => {
    const dayIndex = date.getDate();
    const monthIndex = (date.getMonth() + 1) // October is 0 has to 09 05 need 0 for DateRangePicker
    const month = (monthIndex < 10) ? "0"+ monthIndex : monthIndex;
    const day = (dayIndex < 10) ? "0" + dayIndex : dayIndex;
    const year = date.getFullYear();
    const time = year + "-" + month + "-" + day;
    return time;
}
const increaseDate = (day,date=new Date()) => {
    date.setDate(date.getDate() + day);
    return getDateStr(date)
}
const decreaseDate = (day,date=new Date()) =>{
    date.setDate(date.getDate() - day)
    return getDateStr(date)
}
export {getDateStr, increaseDate, decreaseDate};