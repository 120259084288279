import httpClient from "../../core/httpclient";
import voyageType from "../type/voyage";
import ProjectConfig from '../../config/config'
import XMSErrorHandler from "../../core/xmsErrorHandler";

export const getVoyages = (VoyageRequest) => async dispatch => {
    const EndPoint = `${ProjectConfig.apiUrl}/voyage/getdisplayvoyages`;
    const Request = {
        ...VoyageRequest,
        ScheduleDateBegin: VoyageRequest.ScheduleDateBegin.split('-').join(''), //have to send without -
        ScheduleDateEnd: VoyageRequest.ScheduleDateEnd.split('-').join('')
    }
    dispatch({
        type: voyageType.GETTING_VOYAGES
    })
    httpClient.post(EndPoint, Request)
        .then(response => {
            const Voyages = response.data.result;
            if (Object.keys(Voyages).length > 0) {
                dispatch({
                    type: voyageType.GET_VOYAGES,
                    VoyageData: Voyages,
                });
            } else {
                dispatch({
                    type: voyageType.NO_VOYAGE
                })
            }
        })
        .catch(error => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: voyageType.VOYAGE_CONNECTION_ERROR,
                ErrorTitle: "Error",
                ErrorCode: ErrorCode,
                ErrorMessage: ErrorDescription
            });

        })
}
export const getVoyagePorts = () => dispatch => {
    const EndPoint = `${ProjectConfig.apiUrl}/port/getdisplayports`
    httpClient.post(EndPoint, "").then(response => {
        const Ports = response.data.result;
        dispatch({
            type: voyageType.GET_VOYAGE_PORTS,
            Ports: Ports
        })
    })
        .catch(error => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: voyageType.VOYAGE_CONNECTION_ERROR,
                ErrorTitle: "Error",
                ErrorCode: ErrorCode,
                ErrorMessage: ErrorDescription
            })
        })
}
export const setFirstLoad = (Load) => dispatch => {
    dispatch({
        type: voyageType.SET_FIRST_LOAD,
        FirstLoad: Load
    })
}
export const clearVoyage = () => dispatch => {
    dispatch({
        type: voyageType.CLEAR_VOYAGE
    })
}
export const setVoyageDate = (ScheduleDateBegin, ScheduleDateEnd) => dispatch => {
    dispatch({
        type: voyageType.SET_VOYAGEDATE,
        ScheduleDateBegin: ScheduleDateBegin,
        ScheduleDateEnd: ScheduleDateEnd
    })
}
export const setVoyageDeparturePort = (DeparturePort) => dispatch => {
    dispatch({
        type: voyageType.SET_VOYAGE_DEPARTURE_PORT,
        DeparturePort: DeparturePort
    })
}
export const setVoyageArrivalPort = (ArrivalPort) => dispatch => {
    dispatch({
        type: voyageType.SET_VOYAGE_ARRIVAL_PORT,
        ArrivalPort: ArrivalPort
    })
}
export const setVoyageDirection = (Direction) => dispatch => {
    dispatch({
        type: voyageType.SET_VOYAGE_DIRECTION,
        Direction: Direction
    })
}
export const setVoyageRequest = (VoyageRequestData) => dispatch => {
    dispatch({
        type: voyageType.SET_VOYAGE_REQUEST_DATA,
        VoyageRequestData: VoyageRequestData
    })
}
export const clearVoyageError = () => dispatch => {
    dispatch({
        type: voyageType.CLEAR_VOYAGE_ERROR
    })
}

export const setVoyageError = (ErrorObj) => dispatch => {
    dispatch({
        type: voyageType.VOYAGE_ERROR,
        ErrorTitle: ErrorObj.ErrorTitle,
        ErrorCode: ErrorObj.ErrorCode,
        ErrorMessage: ErrorObj.ErrorMessage
    })
}