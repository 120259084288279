export const classNameSelector = (ColumnDesign, DataObject) => {

    if (ColumnDesign && DataObject) {
        let classObject = { Classname: "", ClassOverWritePriorty: 99999 }
        ColumnDesign.map((column) => {
            if (column.Classname) {
                let ClassOverWritePriorty = column.ClassOverWritePriorty ? column.ClassOverWritePriorty : 0
                if (classObject.ClassOverWritePriorty > ClassOverWritePriorty || classObject.Classname === "") {
                    classObject.Classname = typeof column.Classname === "function" ? column.Classname(DataObject[column.columnId]) : column.Classname
                    classObject.ClassOverWritePriorty = ClassOverWritePriorty
                }
            }
        }
        )
        return classObject.Classname
    } else {
        return ""
    }
}