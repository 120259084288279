const declarationTypes = Object.freeze({
    GET_DECLARATIONS: Symbol('GET_DECLARATIONS'),
    CLEAR_DECLARATIONS: Symbol('CLEAR_DECLARATIONS'),
    DECLARATION_CONNECTION_ERROR : Symbol('DECLARATION_CONNECTION_ERROR'),
    NO_DECLARATION : Symbol('NO_DECLARATION'),
    GETTING_DECLARATIONS : Symbol('GETTING_DECLARATIONS'),
    CLEAR_DECLARATION_ERROR : Symbol('CLEAR_DECLARATION_ERROR'),
    SET_REQUEST_VOYAGE_ID : Symbol('SET_REQUEST_VOYAGE_ID'),
    SET_ERROR : Symbol("SET_ERROR"),
    SET_FIRST_LOAD : Symbol("SET_FIRST_LOAD"),
    UPDATE_REFRESH_TIME : Symbol("UPDATE_REFRESH_TIME")
});

export default declarationTypes;