import React from 'react';
import { Modal } from '@dfds-ui/modal';
import { connect } from 'react-redux';
import {modalHide} from '../../../../store/action/modalActions'
import HistoryTable from './HistoryTable/HistoryTable'
import './HistoryModal.scss'
const HistoryModal = (props) => {
    const {HistoryModal,modalHide} = props;
    return(
    <Modal className={"HistoryModal"} heading="History" isOpen={HistoryModal} onRequestClose={() => modalHide({Modal : "HISTORY_MODAL"})}>
        <HistoryTable />
    </Modal>
    )
}
const mapStateToProps = state => {
    return {
        HistoryModal: state.modal.HistoryModal
    }
}
export default connect(mapStateToProps, {modalHide})(HistoryModal);