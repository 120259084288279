import React, { useEffect, useState } from 'react';
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { BookingTableSchema, DummyBookingData } from './BookingTableSchema'
import { connect, useSelector } from 'react-redux';
import { setSelectedBooking } from '../../../store/action/detailActions'
import ProjectConfig from '../../../config/config';
import { setVoyageId } from '../../../store/action/declarationAction';
import BookingTableErrorComponents from '../../CommonUI/TableErrorComponents/TableErrorComponents'

import './BookingTable.scss'
const BookingTable = (props) => {
    const { VoyageId, FilterText, DetailsData, setSelectedBooking, SelectedBooking,
        WaitForRequest, DetailNotFound, ServiceConnection } = props;
    const BookingDetails = DetailsData && DetailsData.bookingDetails;
    const FilterLogic = () => {
        if (FilterText.trim() === "") return BookingDetails
        let FilteredBookings = []
        BookingDetails.map((Detail) => {
            if (Detail.cargoDetails !== undefined && Detail.cargoDetails !== null) {
                Detail.cargoDetails.map((Cargo) => {
                    if (FilterText === "null") {
                        if (Cargo.mrn === null || Cargo.mrn === undefined || Cargo.mrn === "") {
                            FilteredBookings.push(Detail)
                        }
                    } else {
                        if (Cargo.mrn !== null && Cargo.mrn !== undefined) {
                            if (Cargo.mrn.includes(FilterText)) {
                                FilteredBookings.push(Detail)
                            }
                        }
                    }
                })
            }
        })
        return FilteredBookings;
    }
    const BookingsWithFilter = (FilterText !== null && FilterText !== undefined) ? FilterLogic() : BookingDetails

    const RenderComponents = BookingTableErrorComponents(DetailNotFound,
        !ServiceConnection, WaitForRequest, "Details Not Found", "Connection Error");

    useEffect(() => {
        if (BookingsWithFilter !== null && BookingsWithFilter.length > 0) {
            if (SelectedBooking) {
                SelectedBooking.__selected = false
            }
            BookingsWithFilter[0].__selected = true; // It's not a good method, __properties ' re table's properties
            setSelectedBooking(BookingsWithFilter[0]) // Default selected.
        } else {
            if (SelectedBooking) {
                SelectedBooking.__selected = false
            }
            setSelectedBooking(null)
        }
    }, [DetailsData, FilterText])

    const onRowClick = (Row, index) => {
        setSelectedBooking(Row) // When row clicked change selected booking for cargo table
    }
    return (
        <PerfectTable
            bodyClassName={(WaitForRequest ? "tableSpinning" : "")}
            className={" BookingTable"}
            DataArray={BookingsWithFilter} //BookingData
            TableSchema={BookingTableSchema}
            OnRowClick={onRowClick}
            RowRenderComps={RenderComponents}
        />
    );
}
const mapStateToProps = state => {
    return {
        VoyageId: state.declaration.VoyageId,
        DetailsData: state.detail.DetailsData,
        SelectedBooking: state.detail.SelectedBooking,
        WaitForRequest: state.detail.WaitForRequest,
        DetailNotFound: state.detail.DetailNotFound,
        ServiceConnection: state.detail.ServiceConnection,
        FilterText: state.detail.FilterText
    }
}
export default connect(mapStateToProps, { setSelectedBooking })(BookingTable);