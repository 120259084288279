import React from "react";
import ReactExport from "react-data-export";
import ExcelStyleMaker from './ExcelStyleMaker'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelWithStyle = (props) => {
    const { ButtonComp, DataArray, ExcelSchema, SheetName, FileName,ExtraData } = props;
    if(DataArray === null || DataArray === undefined || typeof DataArray !== "object") return (<></>)
    const BeautifulData = ExcelStyleMaker(DataArray,ExcelSchema,ExtraData)
    return (
        DataArray !== undefined &&
        Object.keys(DataArray).length > 0 &&
        BeautifulData[0].data.length > 0 ?
        <ExcelFile filename={(typeof FileName === "string" ? FileName : "ExcelFile")}  element={ButtonComp}>
            <ExcelSheet dataSet= {BeautifulData} name={SheetName}></ExcelSheet>
        </ExcelFile>:
        ButtonComp
    )
}
export default ExcelWithStyle;