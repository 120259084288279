import ProjectConfig from '../config/config';
export const setUserStorage = (item, Username) => {
    localStorage.setItem(`${ProjectConfig.projectName}_access_token`, item.access_token)
    localStorage.setItem(`${ProjectConfig.projectName}_refresh_token`, item.refresh_token)
    localStorage.setItem(`${ProjectConfig.projectName}_expires_in`, item.expires_in)
    localStorage.setItem(`${ProjectConfig.projectName}_username`, Username)
}
export const getUserStorage = () => {
    return {
        token: {
            access_token: localStorage.getItem(`${ProjectConfig.projectName}_access_token`),
            refresh_token: localStorage.getItem(`${ProjectConfig.projectName}_refresh_token`),
            expires_in: localStorage.getItem(`${ProjectConfig.projectName}_expires_in`)
        },
        Username: localStorage.getItem(`${ProjectConfig.projectName}_username`)
    }
}
export const clearUserStorage = () => {
    localStorage.setItem(`${ProjectConfig.projectName}_access_token`, "")
    localStorage.setItem(`${ProjectConfig.projectName}_refresh_token`, "")
    localStorage.setItem(`${ProjectConfig.projectName}_expires_in`, "")
    localStorage.setItem(`${ProjectConfig.projectName}_username`, "")
}
export const checkUserStorage = () => {
    const userStorage = getUserStorage();
    console.log("Access Token")
    console.log(userStorage.access_token)
    console.log("Refresh Token")
    console.log(userStorage.refresh_token)
    console.log("Expires In")
    console.log(userStorage.expires_in)
    console.log("Username")
    console.log(userStorage.expires_in)
}
