import signTypes from '../type/sign';


const initialState = {
    Signed: false,
    DeclarationId: "",
    PinCode: "",
    ServiceConnection: true,
    WaitForSign: false,
    Error: {
        ErrorTitle: "",
        ErrorMessage: ""
    },
    SignatureDetails: [

    ],
    OnSignModalShow: false,
    Users: null,
    WaitForUsers: false,
    SelectedUser: ""
}
export default (state = initialState, action) => {
    switch (action.type) {
        case signTypes.ON_SIGNING:
            return {
                ...state,
                Signed: false,
                WaitForSign: true,
                ServiceConnection: true,
                Error: {
                    ErrorTitle: "",
                    ErrorMessage: ""
                },
                OnSignModalShow: true
            }
        case signTypes.SHOW_ON_SIGN_MODAL:
            return {
                ...state,
                OnSignModalShow: action.OnSignModalShow
            }
        case signTypes.SIGNED:
            return {
                ...state,
                Signed: true,
                PinCode: "",
                WaitForSign: false,
                ServiceConnection: true,
                Error: {
                    ErrorTitle: "",
                    ErrorMessage: ""
                }
            }
        case signTypes.SIGN_ERROR:
            return {
                ...state,
                Signed: false,
                WaitForSign: false,
                PinCode: "",
                Error: {
                    ErrorTitle: action.ErrorTitle,
                    ErrorMessage: action.ErrorMessage
                },
                SignatureDetails: [...state.SignatureDetails, { Text: action.ErrorMessage, Status: "error" }]
            }
        case signTypes.SIGN_CONNECTION_ERROR:
            return {
                ...state,
                WaitForSign: false,
                PinCode: "",
                Error: {
                    ErrorTitle: action.Error.ErrorTitle,
                    ErrorMessage: action.Error.ErrorMessage
                },
                SignatureDetails: [...state.SignatureDetails, { Text: action.Error.ErrorMessage, Status: "error" }]

            }
        case signTypes.CLEAR_SIGN:
            return {
                ...state,
                Signed: false,
                DeclarationId: "",
                PinCode: "",
                SelectedUser: "",
                Users: null,
                ServiceConnection: true,
                WaitForSign: false,
                Error: {
                    ErrorTitle: "",
                    ErrorMessage: ""
                },
            }
        case signTypes.SET_PINCODE:
            return {
                ...state,
                PinCode: action.PinCode
            }
        case signTypes.CLEAR_SIGN_ERROR:
            return {
                ...state,
                Signed: false,
                ServiceConnection: true,
                WaitForSign: false,
                Error: {
                    ErrorTitle: "",
                    ErrorMessage: ""
                }
            }
        case signTypes.SET_SIGNATURE_DETAILS:
            return {
                ...state,
                SignatureDetails: [...state.SignatureDetails, ...action.SignatureDetails]
            }
        case signTypes.CLEAR_SIGNATURE_DETAILS:
            return {
                ...state,
                SignatureDetails: []
            }
        case signTypes.GET_USERS:
            return {
                ...state,
                Users: action.Users
            }
        case signTypes.GETTING_USERS:
            return {
                ...state,
                WaitForUsers: action.WaitForUsers
            }
        case signTypes.SET_SIGN_USER:
            return {
                ...state,
                SelectedUser: action.SelectedUser
            }
        default:
            return state;

    }
}

