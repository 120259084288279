const adminPanelTypes = Object.freeze({
    IDENTITY_USER_MODAL_SHOW: Symbol('IDENTITY_USER_MODAL_SHOW'),
    IDENTITY_USER_MODAL_HIDE: Symbol('IDENTITY_USER_MODAL_HIDE'),

    DELETE_USER_MODAL_SHOW: Symbol('DELETE_USER_MODAL_SHOW'),
    DELETE_USER_MODAL_HIDE: Symbol('DELETE_USER_MODAL_HIDE'),

    GETTING_USERS: Symbol('GETTING_USERS'),
    GET_USERS: Symbol('GET_USERS'),
    GET_USERS_ERROR: Symbol('GET_USERS_ERROR'),

    GETTING_USER_ROLES: Symbol('GETTING_USER_ROLES'),
    GET_USER_ROLES: Symbol('GET_USER_ROLES'),
    GET_USER_ROLES_ERROR: Symbol('GET_USER_ROLES_ERROR'),

    SAVING_USER: Symbol('SAVING_USER'),
    SAVE_USER: Symbol('SAVE_USER'),
    SAVE_USER_ERROR: Symbol('SAVE_USER_ERROR'),

    SET_ERROR :Symbol('SET_ERROR'),

    DELETING_USER: Symbol('DELETING_USER'),
    DELETE_USER: Symbol('DELETE_USER'),
    DELETE_USER_ERROR: Symbol('DELETE_USER_ERROR'),

    SET_SELECTED_USER: Symbol('SET_SELECTED_USER'),
    SET_IDENTITY_USER_STATUS: Symbol('SET_IDENTITY_USER_STATUS'),
    SET_IDENTITY_USER_USER_NAME: Symbol('SET_IDENTITY_USER_USER_NAME'),
    SET_IDENTITY_USER_NAME: Symbol('SET_IDENTITY_USER_NAME'),
    SET_IDENTITY_USER_SURNAME: Symbol('SET_IDENTITY_USER_SURNAME'),
    SET_IDENTITY_USER_EMAIL: Symbol('SET_IDENTITY_USER_EMAIL'),
    SET_IDENTITY_USER_ROLE: Symbol('SET_IDENTITY_USER_ROLE'),
    SET_IDENTITY_USER_COMPANY_CODE: Symbol('SET_IDENTITY_USER_COMPANY_CODE'),
    SET_IDENTITY_USER_DEPARTMENT: Symbol('SET_IDENTITY_USER_DEPARTMENT'),
    SET_IDENTITY_USER_PASSWORD: Symbol('SET_IDENTITY_USER_PASSWORD'),

});

export default adminPanelTypes;