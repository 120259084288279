export const ArrowLock = () => {
    window.addEventListener("keydown", function (e) {
        if (["ArrowLeft", "ArrowRight"].indexOf(e.key) > -1) {
            const activeElement = document.activeElement
            const elementDataset = activeElement ? activeElement.dataset : false
            if (!elementDataset) return;
            if (elementDataset.lock === undefined) return
            const elementLock = (elementDataset.lock === "on" ? true : false)
            if (!elementLock) {
                const TableContainer = activeElement.parentElement.parentElement.parentElement.parentElement.parentElement;
                if (e.key === "ArrowLeft") {
                    e.preventDefault();
                    TableContainer.scrollLeft = TableContainer.scrollLeft - document.activeElement.offsetWidth;
                } else if (e.key === "ArrowRight") {
                    e.preventDefault();
                    const prevElement = activeElement.previousSibling
                    const prevElementDataset = prevElement ? prevElement.dataset : false
                    const prevElementLock = prevElementDataset ? (prevElementDataset.lock === "on" ? true : false) : false
                    if (!prevElementLock) {
                        TableContainer.scrollLeft = TableContainer.scrollLeft + prevElement.offsetWidth;
                    } else {
                        // e.preventDefault();
                        TableContainer.scrollLeft = 0;
                    }
                }
            }
            e.preventDefault();

        }
    }, false);
}