import React from 'react';
import { Button } from '@dfds-ui/react-components';
import history from '../../../core/history';
import { connect } from 'react-redux';
import { modalShow } from '../../../store/action/modalActions'


const DeclarationTableHeader = (props) => {
    const { modalShow, WaitForRequest,ServiceConnection } = props;
    const onPDFClick = () => {
        modalShow({ Modal: "PDF_MODAL" })
    }
    return (
        <div className="DeclarationTableHeader">
        </div>
    );
}
const mapsToProps = (state) => {
    return {
        WaitForRequest: state.declaration.WaitForRequest,
        ServiceConnection : state.declaration.ServiceConnection
    }
}
export default connect(mapsToProps, { modalShow })(DeclarationTableHeader);