import React from 'react'

const OptionMapper = (props) => {
    const {Options,DisableOptionValue,MainOption} = props.OptionObject;
    if(Options === undefined || MainOption === undefined) return <></>;
    return(
        <>
            <option defaultValue={MainOption.Value} selected disabled>{MainOption.ValueText}</option>
            {
                Options &&
                Options.map((Option,key)=>{
                return (DisableOptionValue !== Option.Value || DisableOptionValue === "") ? <option key={key} value={Option.Value}>{Option.ValueText}</option> :
                        <option key={key} value={Option.Value} disabled>{Option.ValueText}</option>
                })
            }
        </>
    )
}
export default OptionMapper;