import React from 'react';
import { Search } from '@dfds-ui/icons';
import './TableError.css';
const TableErrorDiv = props => {
    const { ShowLogo, Logo, FontSize, FontColor, Size, Message } = props;
    const LogoStyle = {
        width: Size ? Size + "px" : "50px",
        height: Size ? Size + "px" : "50px",
        top: 'calc(50% - ' + (Size ? Size / 2 : 25) + 'px)'
    }
    const MessageStyle = {
        fontSize: FontSize ? FontSize + "px" : "16px",
        color: FontColor ? FontColor : "#000000",
        top: 'calc(50% + ' + (Size ? Size / 2 : 25) + 'px)'
    }
    return (
        <>
        <div className="minSizeDiv"></div>
            {ShowLogo && (Logo === null || Logo === undefined) ?
                <Search className="searchLogo" style={LogoStyle} /> : Logo}
            <p style={MessageStyle} className="messageSpan">{Message}</p>
        </>
    );
}
export default TableErrorDiv;