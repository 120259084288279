import React from 'react'
import './RowCell.scss'
import PropTypes from 'prop-types';
import LeftCalc from '../../../Helper/LeftCalculator';
import DataTypes from '../../../Helper/Component/DataTypes/DataTypes'
import { Radio } from '@dfds-ui/react-components';

export default class RowCell extends React.Component {
    constructor(props) {
        super(props)
        this.state = { style: {} }
        this.tdRef = React.createRef();
    }
    shouldComponentUpdate(nextProp, nextState, nextContext) {
        if (nextState !== this.state) {
            return true;
        }
        if (nextProp.CellData !== this.props.CellData) {
            return true
        }
        if (nextProp.DataDesign !== this.props.DataDesign) {
            return true
        }
        return false
    }
    UNSAFE_componentWillMount() {
        const columnDesigns = this.props.ColumnDesigns
        const designIndex = this.props.DesignIndex

        this.DesignLock = columnDesigns[designIndex].Options ? (columnDesigns[designIndex].Options.DesignLock) : false
        if (this.DesignLock.Default) {
            const totalWidth = LeftCalc(columnDesigns, designIndex)
            this.setState({
                style: {
                    position: "sticky",
                    left: totalWidth + "px",
                    // background: "white",
                    width: this.DesignLock.Width,
                }
            })
        }
    }

    render() {
        const dataDesign = this.props.DataDesign;
        const RowData = this.props.RowData
        const RowIndex = this.props.RowIndex
        const _onRowClick = this.props.onRowClick
        const numberDigit = dataDesign.numberDigit
        let CellData;
        if (dataDesign.dataType === "JustTable-RowRadioButton") {
            if (RowIndex === 0) {
                _onRowClick(null, RowData)
            }
            CellData = <Radio id={RowIndex + "_radioButton"}
                defaultChecked={RowIndex === 0}
                className="rowRadioButton"
                size={"small"} name="rowRadioButton"
                onChange={(event) => { }} />
        } else {
            CellData = DataTypes(dataDesign.dataType, this.props.CellData, dataDesign.dataCheck, null, null, null, numberDigit, (dataDesign.dataLanguage ? dataDesign.dataLanguage : "en"))
        }

        return (
            <td
                ref={this.tdRef}
                onKeyDown={this._onKeyDown}
                style={this.state.style}
                data-field={dataDesign.columnId}
                data-lock={this.DesignLock ? "on" : "off"}
                className="JustStaticTable-RowCell">
                {CellData}
            </td>)
    }
}
RowCell.propTypes = {
    cellData: PropTypes.any,
    DataDesign: PropTypes.object,
    DesignIndex: PropTypes.number,
    ColumnDesigns: PropTypes.array,
}
RowCell.defaultProps = {
    cellData: null,
    DesignIndex: 0,
    RowIndex: 0,
    ColumnDesigns: [],
    DataDesign: {}
}