import React from 'react';
import { NavBarItem } from '@dfds-ui/react-components'
import history from '../../core/history'
const NavBarItemDesigner = (props) => {
    const { pageName, config } = props;
    // debugger
    return (
        config.map((navObj) => {
            if (navObj.pageName === pageName) {
                return navObj.Items.map((navItem,key) => {
                    if (navItem.selected) {
                        return <NavBarItem key={key} className="navBarItem Selected" onClick={()=>history.push(navItem.path)} selected>{navItem.text}</NavBarItem>
                    } else {
                        if(navItem.active){
                            return <NavBarItem key={key} className="navBarItem Active" onClick={()=>history.push(navItem.path)}>{navItem.text}</NavBarItem>
                        }else{
                           return <NavBarItem key={key} className="navBarItem deActive">{navItem.text}</NavBarItem>
                        }
                    }
                })
            }
        })
    )
}

export default NavBarItemDesigner;
