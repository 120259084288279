import React from 'react'
import './JustHeader.scss'
import HeaderCell from './HeaderCell/HeaderCell'
import PropTypes from 'prop-types';

export default class TableHeader extends React.Component {
    // constructor() {
    //     super()
    // }
    shouldComponentUpdate(nextProps, nextStates) {
        if (nextProps.ColumnDesigns !== this.props.ColumnDesigns) {
            return true;
        }
        return false;
    }
    render() {
        const columnDesigns = this.props.ColumnDesigns;
        const _onHeaderRowClick = this.props.onHeaderRowClick
        const _onHeaderCellClick = this.props.onHeaderCellClick
        return (
            <tr onClick={(e) => _onHeaderRowClick(e)
            } className="JustTable-TableHeader" >
                {
                    columnDesigns.map((columnDesign, index) => {
                        if (!columnDesign.hide) {
                            return <HeaderCell
                                onHeaderCellClick={_onHeaderCellClick}
                                key={index}
                                designIndex={index}
                                ColumnDesigns={columnDesigns}
                                ColumnDesign={columnDesign}
                                onSelectall={this.props.onSelectall}
                                Selection={this.props.Selection}
                                SelectionPermission={this.props.SelectionPermission}
                                DataArray={this.props.DataArray}
                                AfterSort={this.props.AfterSort}
                                ParentIds={columnDesign ? columnDesign.parentIds : []}
                                TableBusy={this.props.TableBusy}
                            />
                        } else {
                            return <th style={{ display: "none" }} key={index}></th>
                        }
                    })
                }
            </tr >
        )
    }
}

TableHeader.propTypes = {
    ColumnDesigns: PropTypes.array,
    onHeaderRowClick: PropTypes.func,
    onHeaderCellClick: PropTypes.func
}
TableHeader.defaultProps = {
    ColumnDesigns: [],
    onHeaderRowClick: () => { },
    onHeaderCellClick: () => { }
}