export const BookingTableSchema = {
    ColumnDesign: [
        {
            dataId: "voyageNumber",
            text: "Voyage Number",
        },
        {
            dataId: "releaseNumber",
            text: "Release Number",
        },
        {
            dataId: "boLNumber",
            text: "Bolnumber"
        },
        {
            dataId: "unitType",
            text: "Unit Type",
        },
        {
            dataId: "unitNumber",
            text: "Unit Number",
        },
        {
            dataId: "vehicleRegisterNumber",
            text: "V.R.N.",
            infoText: "Vehicle Register Number"
        },
        {
            dataId: "shipperName",
            text: "Shipper Name",
        },
        {
            dataId: "consigneeName",
            text: "Consignee Name",
        },
        {
            dataId: "totalQuantityOfCargo",
            text: "T.Q.C.",
            infoText: "Total Quantity Of Cargo"
        },
        {
            dataId: "totalGrossWeightOfCargo",
            text: "T.G.W.C.",
            infoText: "Total Gross Weight Of Cargo"
        },

    ],
    DataDesign: [
        {
            dataId: "voyageNumber",
            // icon : "ship"
        },

        {
            dataId: "releaseNumber",
        },
        {
            dataId: "boLNumber",
        },
        {
            dataId: "unitType",
        },
        {
            dataId: "unitNumber",
        },
        {
            dataId: "vehicleRegisterNumber",
        },
        {
            dataId: "shipperName",
        }
        ,
        {
            dataId: "consigneeName",
        }
        ,
        {
            dataId: "totalQuantityOfCargo",
        },
        {
            dataId: "totalGrossWeightOfCargo",
        }
    ],
    Selectable: true
}
export const DummyBookingData = [
    {
        voyageNumber: 123456,
        releaseNumber: 11234,
        unitType: "TRA",
        vrn: "34 AB 34",
        shipperName: "FurkanSeaWays",
        consigneeName: "Furkan",
        totalQuantity: 50,
        totalGrossWeight: 3450,
        __selected: true
    },
    {
        voyageNumber: 123456,
        releaseNumber: 11234,
        unitType: "TRA",
        vrn: "34 AB 34",
        shipperName: "FurkanSeaWays",
        consigneeName: "Furkan",
        totalQuantity: 50,
        totalGrossWeight: 3450
    },
    {
        voyageNumber: 123456,
        releaseNumber: 11234,
        unitType: "TRA",
        vrn: "34 AB 34",
        shipperName: "FurkanSeaWays",
        consigneeName: "Furkan",
        totalQuantity: 50,
        totalGrossWeight: 3450
    },
    {
        voyageNumber: 123456,
        releaseNumber: 11234,
        unitType: "TRA",
        vrn: "34 AB 34",
        shipperName: "FurkanSeaWays",
        consigneeName: "Furkan",
        totalQuantity: 50,
        totalGrossWeight: 3450
    },
    {
        voyageNumber: 123456,
        releaseNumber: 11234,
        unitType: "TRA",
        vrn: "34 AB 34",
        shipperName: "FurkanSeaWays",
        consigneeName: "Furkan",
        totalQuantity: 50,
        totalGrossWeight: 3450
    }
]