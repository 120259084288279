import React from 'react'
import { Pdf, Edit } from '@dfds-ui/icons'
import { MdHistory } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import ReactTooltip from 'react-tooltip';
import { Spinner } from '@dfds-ui/react-components';
export const DeclarationTableSchema = {
    ColumnDesign: [
        {
            dataId: "declarationTypeDisplay",
            text: "Declaration Type",
        },
        {
            dataId: "status",
            text: "Status",
        },
        {
            dataId: "updateDate",
            text: "Process Date",
        },
        {
            dataId: "user",
            text: "User"
        },
        {
            dataId: "registrationNumber",
            text: "Registration Number",
        },
        {
            dataId: "showDownloadButton",
            text: "Download"
        },
        {
            dataId: "showManuelMatchButton",
            text: "M. Update"
        },
        {
            dataId: "showSignButton",
            text: "E-Sign"
        },
        {
            dataId: "History",
            text: "History"
        },
        {
            dataId: "showPDFButton",
            text: "PDF"
        }
    ],
    DataDesign: [
        {
            dataId: "declarationTypeDisplay",
        },
        {
            dataId: "status",
        },
        {
            dataId: "updateDate",
            type: "DATETIMEUTC",
        },
        {
            dataId: "user",
        },
        {
            dataId: "registrationNumber",
        },
        {
            dataId: "showDownloadButton",
            DownloadStatus: { Status: false },
            multipleControl: true,
            controlIds: ["showDownloadButton"],
            controlResults: {
                forAction: (controls, onClick, row, RenderTable, DownloadStatus) => createButton(controls, onClick, row, RenderTable, DownloadStatus)
            }
        },
        {
            dataId: "showManuelMatchButton",
            multipleControl: true,
            controlIds: ["showManuelMatchButton"],
            controlResults: {
                forAction: (controls, onClick, row) => manualButton(controls, onClick, row)
            }
        },
        {
            dataId: "showSignButton",
            multipleControl: true,
            controlIds: ["showSignButton"],
            controlResults: {
                forAction: (controls, onClick, row) => signButton(controls, onClick, row)
            }
        },
        {
            dataId: "History",
            multipleControl: true,
            controlIds: ["History"],
            controlResults: {
                forAction: (controls, onClick, row) => historyButton(controls, onClick, row)
            }
        },
        {
            dataId: "showPDFButton",
            multipleControl: true,
            controlIds: ["showPDFButton"],
            DownloadStatus: { Status: false },
            controlResults: {
                forAction: (controls, onClick, row, RenderTable, DownloadStatus) => pdfButton(controls, onClick, row, RenderTable, DownloadStatus)
            }
        }
    ]
}
const createButton = (controls, onClick, row, RenderTable, DownloadStatus) => {
    if (controls[0]) {
        if (DownloadStatus) {
            if (!DownloadStatus.Status)
                return (
                    <>
                        <ReactTooltip id={"DownloadTooltip"} type='info' effect="solid">
                            Download
                        </ReactTooltip>
                        <IoMdDownload data-tip data-for={"DownloadTooltip"} onClick={() => onClick(row, DownloadStatus, RenderTable)} className="DownloadButton reactIcon" />
                    </>
                )
            else
                return (
                    <>
                        <ReactTooltip id={"DownloadTooltip"} type='info' effect="solid">
                            Downloading
                        </ReactTooltip>
                        <Spinner instant={true} data-tip data-for={"DownloadTooltip"} className="reactIcon DOWNLOADING" />
                    </>
                )
        }
        else {
            return (<IoMdDownload data-tip data-for={"DownloadTooltip"} onClick={() => onClick(row, DownloadStatus, RenderTable)} className="DownloadButton reactIcon" />)

        }
    }
}
const manualButton = (controls, onClick, row) => {
    if (controls[0]) {
        return (
            <>
                <ReactTooltip id={"ManualUpdateTooltip"} type='info' effect="solid">
                    Manual Update
                </ReactTooltip>
                <Edit data-tip data-for={"ManualUpdateTooltip"} onClick={() => onClick(row)} className="ManualButton reactIcon" />
            </>
        )
    }
}
const historyButton = (controls, onClick, row) => {
    return (
        <>
            <ReactTooltip id={"HistoryTooltip"} type='info' effect="solid">
                Check History
            </ReactTooltip>
            <MdHistory data-tip data-for={"HistoryTooltip"} onClick={() => onClick(row)} className="HistoryButton reactIcon" />
        </>
    )
}
const signButton = (controls, onClick, row) => {
    if (controls[0]) {
        return (
            <>
                <ReactTooltip id={"ESignature"} type='info' effect="solid">
                    E-Signature
                </ReactTooltip>

                <FaFileSignature data-tip data-for={"ESignature"} onClick={() => onClick(row)} className="SignButton reactIcon" />
            </>
        )
    }
}
const pdfButton = (controls, onClick, row, RenderTable, DownloadStatus) => {
    if (controls[0]) {
        if (DownloadStatus) {
            if (!DownloadStatus.Status)
                return (
                    <>
                        <ReactTooltip id={"PDFTooltip"} type='info' effect="solid">
                            Download PDF
                        </ReactTooltip>
                        <Pdf data-tip data-for={"PDFTooltip"} onClick={() => onClick(row, DownloadStatus, RenderTable)} className="PDFButton reactIcon" />
                    </>
                )
            else
                return (
                    <>
                        <ReactTooltip id={"PDFTooltip"} type='info' effect="solid">
                            Downloading PDF
                        </ReactTooltip>
                        <Spinner instant={true} data-tip data-for={"PDFTooltip"} className="PDFButton reactIcon DOWNLOADING" />
                    </>
                )
        }
        else {
            return <Pdf data-tip data-for={"PDFTooltip"} onClick={() => onClick(row, DownloadStatus, RenderTable)} className="PDFButton reactIcon" />

        }
    }
}