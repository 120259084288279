import modalTypes from "../type/modal";

export const modalShow = (props) =>dispatch => {
    const {Modal,Row} = props;
    dispatch({
        type : modalTypes.MODAL_SHOW,
        Modal : Modal,
        Row : Row

    })
}
export const modalHide = (props) => dispatch => {
    const {Modal,Row} = props;
    dispatch({
        type : modalTypes.MODAL_HIDE,
        Modal : Modal,
        Row : Row
    })
}

