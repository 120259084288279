import signTypes from "../type/sign";
import ProjectConfig from "../../config/config";
import XMSErrorHandler from "../../core/xmsErrorHandler";
import httpClient from "../../core/httpclient";

export const ConnectToLocalService = (DeclarationId, PinCode, Username) => dispatch => {
    const AccessToken = localStorage.getItem(`${ProjectConfig.projectName}_access_token`);
    const SignLocalServiceURL = `${ProjectConfig.localServiceUrl}SignFile?PinCode=${PinCode}&username=${Username}&SummaryDeclarationId=${DeclarationId}&Bearer=${AccessToken}`
    const ConnectionConfig = {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify("")
    }
    dispatch({
        type: signTypes.ON_SIGNING,
    })
    fetch(SignLocalServiceURL, ConnectionConfig)

        .then(response => {
            response.json()
                .then(response => {
                    if (response.Success) {
                        dispatch({
                            type: signTypes.SIGNED
                        })
                    }
                    else {
                        dispatch({
                            type: signTypes.SIGN_ERROR,
                            ErrorTitle: "Something went wrong",
                            ErrorMessage: response.Message
                        })
                    }
                })
                .catch(error => {
                    dispatch({
                        type: signTypes.SIGN_ERROR,
                        ErrorTitle: "Something went wrong.",
                        ErrorMessage: "It couldn't signed."
                    })
                })
        })
        .catch(error => {
            dispatch({
                type: signTypes.SIGN_CONNECTION_ERROR,
                Error: {
                    ErrorTitle: "Error",
                    ErrorMessage: "Connection Error. Check your local service."
                }
            })
        })
}
export const CheckLocalService = (DeclarationId, PinCode) => dispatch => {
    const AccessToken = localStorage.getItem(`${ProjectConfig.projectName}_access_token`);
    const SignLocalServiceURL = `${ProjectConfig.localServiceUrl}ShowLog?Bearer=${AccessToken}`
    const ConnectionConfig = {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify("")
    }
    fetch(SignLocalServiceURL, ConnectionConfig)
        .then(response => {
            response.json()
                .then(response => {
                })
                .catch(error => {
                })
        })
        .catch(error => {
            dispatch({
            })
        })
}
export const setPinCode = (PinCode) => dispatch => {
    dispatch({
        type: signTypes.SET_PINCODE,
        PinCode: PinCode
    })
}
export const clearSignError = () => dispatch => {
    dispatch({
        type: signTypes.CLEAR_SIGN_ERROR
    })
}
export const setSignatureDetails = (Details) => dispatch => {
    dispatch({
        type: signTypes.SET_SIGNATURE_DETAILS,
        SignatureDetails: Details
    })
}
export const clearSignatureDetails = () => dispatch => {
    dispatch({
        type: signTypes.CLEAR_SIGNATURE_DETAILS
    })
}
export const ShowHideOnSignModal = (Show) => dispatch => {
    dispatch({
        type: signTypes.SHOW_ON_SIGN_MODAL,
        OnSignModalShow: Show
    })
}
export const clearSign = () => dispatch => {
    dispatch({
        type: signTypes.CLEAR_SIGN
    })
}
export const getUsers = () => dispatch => {
    const EndPoint = `${ProjectConfig.apiUrl}/customsuser/getcustomsusers`;
    dispatch({
        type: signTypes.GETTING_USERS,
        WaitForUsers: true
    })
    httpClient.post(EndPoint, JSON.stringify(""))
        .then(response => {
            const Users = response.data.result;
            dispatch({
                type : signTypes.GET_USERS,
                Users : Users
            })
        })
        .catch(error => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            //Burayı devam ediceksin. XXXXXXXXX
        })
        .finally(() => {
            dispatch({
                type: signTypes.GETTING_USERS,
                WaitForUsers: false
            })
        })
}
export const setSelectedUser = (User) => dispatch => {
    dispatch({
        type: signTypes.SET_SIGN_USER,
        SelectedUser : User
    })
}

