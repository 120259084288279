import React from 'react'
import JustHeader from './JustHeader/JustHeader'
import './JustStaticTable.scss'
import JustRow from './JustRow/JustRow'

import PropTypes from 'prop-types';
import JustWait from '../Helper/Component/JustAnimations/JustWait/JustWait'
import JustNotFound from '../Helper/Component/JustAnimations/JustNotFound/JustNotFound'
import JustError from '../Helper/Component/JustAnimations/JustError/JustError'
export default class JustTable extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.JustData !== this.props.JustData) {
            return true;
        }
        if (nextProps.JustAnimations !== this.props.JustAnimations) {
            return true
        }
        if (nextProps.JustSchema !== this.props.JustSchema) {
            return true
        }
        return false;
    }

    render() {
        const TableSchema = this.props.JustSchema;
        //Empty TableSchema (Noschema = Notable!)
        if (Object.entries(TableSchema).length === 0) return <></>

        const columnDesigns = TableSchema.ColumnDesign;
        const dataDesign = TableSchema.DataDesign;
        const dataArray = this.props.JustData;

        //Event Listeners
        const Events = this.props.JustEvents;
        const _onRowClick = Events ? (Events.onRowClick ? Events.onRowClick : () => { }) : () => { }
        const _onHeaderCellClick = Events ? (Events.onHeaderCellClick ? Events.onHeaderCellClick : () => { }) : () => { }
        const _onHeaderRowClick = Events ? (Events.onHeaderRowClick ? Events.onHeaderRowClick : () => { }) : () => { }
        //-------


        const WaitForRequest = this.props.JustAnimations ? this.props.JustAnimations.WaitForRequest : false

        // const NotFound = this.props.JustAnimations ? this.props.JustAnimations.DataNotFound : false
        const NetworkError = this.props.JustAnimations ? this.props.JustAnimations.NetworkError : false
        const notFoundMessage = this.props.JustAnimationMessages ? this.props.JustAnimationMessages.NotFound : "Not Found"
        const networkMessage = this.props.JustAnimationMessages ? this.props.JustAnimationMessages.NoConnection : "No Connection"

        const CanvasWidth = this.props.CanvasWidth;
        const notSearched = this.props.notSearched;
        const notSearchedText = notSearched && notSearched.Text;
        const notSearchedStatus = notSearched && notSearched.Status

        const RadioButton = TableSchema.RadioButton
        if (RadioButton) {
            columnDesigns.unshift({
                columnId: "JustStaticTable-RowRadioButton",
                Text: "",
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "40px"
                    },
                }
            })
            dataDesign.unshift({
                columnId: "JustTable-RowRadioButton",
                dataType: "JustTable-RowRadioButton"
            })
        }
        return (
            <div className="JustStaticTable-Container">
                <table className="JustStaticTable-Table">
                    <thead className="JustStaticTable-Table-Header">
                        <JustHeader
                            onHeaderRowClick={_onHeaderRowClick}
                            ColumnDesigns={columnDesigns}
                            onHeaderCellClick={_onHeaderCellClick}
                        />

                    </thead>

                    {
                        notSearchedStatus ?
                            <JustNotFound Text={notSearchedText} CanvasWidth={CanvasWidth} />
                            :
                            NetworkError ?
                                <JustError Text={networkMessage} CanvasWidth={CanvasWidth} />
                                :
                                WaitForRequest ?
                                    <JustWait CanvasWidth={CanvasWidth} />
                                    :
                                    (
                                        (dataArray) ?
                                            (dataArray.length > 0) ?
                                                <tbody className="JustTable-Table-Rows">
                                                    {
                                                        dataArray.map((rowData, key) => {
                                                            return <JustRow
                                                                onRowClick={_onRowClick}
                                                                RowIndex={key}
                                                                key={key}
                                                                DataDesign={dataDesign}
                                                                RowData={rowData}
                                                                ColumnDesigns={columnDesigns}
                                                                className={RadioButton ? "RowRadioButtonEnable" : ""}
                                                                RadioButton={RadioButton}
                                                            />
                                                        })
                                                    }
                                                </tbody>
                                                :
                                                <JustNotFound Text={notFoundMessage} CanvasWidth={CanvasWidth} />

                                            :
                                            <JustNotFound Text={notFoundMessage} CanvasWidth={CanvasWidth} />
                                    )
                    }

                </table>

            </div >
        )
    }
}
JustTable.propTypes = {
    JustData: PropTypes.array,
    JustSchema: PropTypes.object
}
JustTable.defaultProps = {
    JustData: [],
    JustSchema: {},
}