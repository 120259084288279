import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import VoyageReducer from './voyageReducer';
import PageReducer from './pageReducer';
import ModalReducer from './modalReducer';
import declarationReducer from './declarationReducer';
import detailReducer from './detailReducer'
import signReducer from './signReducer';
import AdminPanelReducer from './adminPanelReducer';

export default combineReducers({
    user: UserReducer,
    voyage : VoyageReducer,
    page : PageReducer,
    modal : ModalReducer,
    declaration : declarationReducer,
    detail : detailReducer,
    sign : signReducer,
    adminPanel: AdminPanelReducer,
});