import React from 'react';
import './DotAnimButton.scss';

const DotButton = props => {
    const {waitForRequest,className,onClick,ButtonLabel} = props;
    return (
        <button className= {waitForRequest ? "dotBtn "+className+" disabled" : "dotBtn "+className}
            onClick={onClick}>
                {ButtonLabel}
                {waitForRequest ? <>ING<span> . </span><span>. </span><span>.</span></> : ""}
        </button>
    )
}
export default DotButton;