import React from 'react'
import { Close } from '@dfds-ui/icons'
import './TagBox.scss'
const TagBox = (props) => {
    const { FilterText, onDeleteClick } = props
    return (
        (FilterText) ?
            (FilterText.trim() !== "") ?
                (<div className="TagBox-Container">
                    <span id="TagBox-FilterText">
                        {FilterText}
                    </span>
                    <Close id="TagBox-CloseButton" onClick={(e) => onDeleteClick(e)} />
                </div>) : <></> :
            <></>
    )
}
export default TagBox;