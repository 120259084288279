import React from 'react'
import HistoryModal from './Modals/HistoryModal/HistoryModal'
import ManualModal from './Modals/ManualModal/ManualModal'
import SignModal from './Modals/SignModal/SignModal'

const ModalController = (props) => {
    return(
    <>
        <HistoryModal />
        <ManualModal />
        <SignModal />
    </>
    );
}
export default ModalController;