import React from 'react'
import { Drawer, ListItem, ListText } from '@dfds-ui/react-components'
import { logOutUser } from '../../store/action/userActions'
import { connect } from 'react-redux';
import { clearVoyage } from '../../store/action/voyageAction'
import history from '../../core/history';
import { getAuthorization } from '../../core/authentication';
const UserDrawer = ({ logOutUser, clearVoyage }) => {
    const userRole = getAuthorization()

    return (
        <Drawer className="UserDrawer-Pop" position={"right"}>
            {
                userRole === "Admin" &&
                <ListItem
                    onClick={() => {
                        history.push("/adminpanel")
                    }}
                    clickable noBorder >
                    <ListText bold={true}>Admin Panel</ListText>
                </ListItem>
            }

            <ListItem
                onClick={() => {
                    logOutUser()
                    clearVoyage()
                    history.push("/")
                }}
                clickable noBorder >
                <ListText bold={true}>Logout</ListText>
            </ListItem>
        </Drawer>
    )
}
export default connect(null, { logOutUser, clearVoyage })(UserDrawer);
