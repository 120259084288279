import React from 'react'
import ReactDOM from 'react-dom'
import './JustTooltip.scss'
import JustPortal from '../JustPortal/JustPortal'

export default class JustTooltip extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ToolTipStyle: null, selfRef: null, parentNode: null }
    }
    shouldComponentUpdate(nextProps, nextStates) {
        if (nextStates.selfRef !== this.state.selfRef || nextStates.parentNode !== this.state.parentNode) {
            if (nextStates.selfRef === null) return
            setTimeout(() => nextStates.selfRef.focus(), 100)
            const pPos = this.state.parentNode.getBoundingClientRect()
            nextStates.selfRef.style.top = (pPos.top - nextStates.selfRef.offsetHeight) + "px"
            const pCenter = this.state.parentNode.offsetWidth / 2;
            const selfCenter = nextStates.selfRef.offsetWidth / 2
            const componentCenterOffset = pCenter - selfCenter
            nextStates.selfRef.style.left = (parseFloat(nextStates.selfRef.style.left.split("px")[0]) + componentCenterOffset) + "px"
            return false
        }
        return true
    }

    UNSAFE_componentWillMount() {
        const relatedRef = this.props.ParentRef.current
        if (relatedRef !== undefined && relatedRef !== null) {
            const domNode = ReactDOM.findDOMNode(relatedRef)
            var relatedRef_Position = domNode.getBoundingClientRect()
            this.setState({
                ToolTipStyle: {
                    top: (relatedRef_Position.top) + "px",
                    left: (relatedRef_Position.left) + "px"
                }, parentNode: domNode
            }
            )
        }
    }
    render() {
        const toolTipStyle = this.state.ToolTipStyle
        const toolTipText = this.props.ToolTipText ? this.props.ToolTipText : ""
        return (
            <JustPortal portalId="JustToolTip-Portal">
                <div ref={elem => this.setState({ selfRef: elem })}
                    tabIndex="0" onBlur={(e) => this.props.onBlur(e)}
                    onMouseLeave={(e) => this.props.onMouseLeave(e)}
                    style={toolTipStyle} className="JustTooltip-Container">
                    <div className="JustTooltip-Context">
                        <span id="JustTooltip-Text">{toolTipText}</span>
                    </div>
                    <div className="JustTooltip-Edge" />
                </div>
            </JustPortal>
        )
    }

}