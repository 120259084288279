import React from 'react'
import { IoMdDownload } from "react-icons/io"
import { Down } from '@dfds-ui/icons'
import CopyDescription from '../../../../CommonUI/CopyButton/CopyButton'
export const HistoryTableSchema = {
    ColumnDesign: [
        {
            dataId: "status",
            text: "Status",
        },
        {
            dataId: "user",
            text: "User",
        },
        {
            dataId: "date",
            text: "Date",
        },
        {
            dataId: "download",
            text: "Link"
        },
        {
            dataId: "description",
            text: "Description",
        }
    ],
    DataDesign: [
        {
            dataId: "status",
            // icon : "ship"
        },

        {
            dataId: "user",
        },
        {
            dataId: "date",
            type: "datetimeutc",
        },
        {
            dataId: "download",
            multipleControl: true,
            controlIds: ["downloadLink"],
            controlResults: {
                forAction: (controls, onClick, row) => downloadLink(controls, onClick, row)
            }

        },
        {
            dataId: "description",
            multipleControl: true,
            show: false,
            controlIds: ["status"],
            controlResults: {
                forAction: (controls, onClick, row, RenderTable) => showDescription(controls, onClick, row, RenderTable)
            }
        }
    ]
}
const downloadLink = (control, onClick, row) => {

    if (control[0] !== "" && control[0] !== undefined && control[0] !== null) {
        return <IoMdDownload onClick={() => onClick(row)} className="DownloadButton reactIcon" />;
    }
}
const showDescription = (control, onClick, row, RenderTable) => {
    const DescList = row && row.description && row.description.split('\n')
    if (control[0] === "RejectedByCustoms" && DescList.length > 1) {
        if (row.__collapse) {
            return <>
                <span id="cellText"
                    onClick={() => { shopCollapse(row, RenderTable) }}
                    className="Collapsable">
                    {DescList[0]}
                    <Down className="Collapsable-Arrow Arrow-Up" />
                    {
                        DescList.map((description, index) => {
                            if (index === 0) return '\n'
                            return (index === 1) ? description : "\n" + description
                        })
                    }
                </span>
                <CopyDescription CopyText={row.description} className="CopyDesc-Container" />
            </>
        } else {
            return <>
                <span id="cellText"
                    onClick={() => { shopCollapse(row, RenderTable) }}
                    className="Collapsable">
                    {DescList[0]}
                    <Down className="Collapsable-Arrow Arrow-Down" />
                </span>
                <CopyDescription CopyText={row.description} className="CopyDesc-Container" />
            </>
        }
    } else {
        return <span id="cellText">{row.description}</span>
    }
}
const shopCollapse = (Row, RenderTable) => {
    if (Row.__collapse) {
        Row.__collapse = false
    } else {
        Row.__collapse = true
    }
    RenderTable.setTableRender(!RenderTable.tableRender)
}
