const ExcelStyleMaker = (DataArray, ExcelStyle, ExtraData) => {
    let RootArray = [];
    let StyleObject = {};
    StyleObject.columns = ExcelStyle[0].columns;
    StyleObject.data = [];
    // StyleObject.data.push(ExcelStyle[0].columnsCells);
    const AbsDataArray = Object.keys(DataArray).map((i) => DataArray[i])
    const FilterDataArray = ExcelStyle[0].FilterData ? filterValue(AbsDataArray, ExcelStyle[0].FilterData) :
        AbsDataArray;
    const FinalArray = ExtraData ? bindDataBeforeView(FilterDataArray,ExcelStyle,ExtraData) : FilterDataArray 
    FinalArray.map((DataObj) => {
        let tmpArray = [];
        ExcelStyle[0].ValueIds.map((dataProp) => {
            let tmpObj = {};
            tmpObj.value = checkValue(DataObj[dataProp], dataProp, ExcelStyle[0].ValueType);
            tmpObj.style = {}
            tmpObj.style.font = {}
            tmpObj.style.font.sz = ExcelStyle[0].ValueStyle.size
            tmpObj.style.font.bold = ExcelStyle[0].ValueStyle.bold
            tmpArray.push(tmpObj);
        })
        StyleObject.data.push(tmpArray);
    })
    RootArray.push(StyleObject)
    return RootArray;
}
const checkValue = (data, dataProp, valueType) => {
    if (valueType[dataProp]) return valueType[dataProp](data)
    if (data === null) return "";
    if (typeof data === "number") return data.toString()
    return data;
}
const filterValue = (DataArray, FilterData) => {
    return DataArray.filter((DataObj) => {
        let filterResult = true;
        Object.keys(FilterData).map((key) => {
            filterResult = filterResult && FilterData[key](DataObj[key])
        })
        return filterResult;
    })

}
const bindDataBeforeView = (DataArray, ExcelStyle, ExtraData) => {
    DataArray.map((DataObj) => {
        return ExcelStyle[0].ExtraDataProperty.map((ExDataProp)=>{
            if(DataObj[ExDataProp] !== undefined) return null;
            return DataObj[ExDataProp] = ExtraData[ExDataProp]
        })
    })
    return DataArray;
}
export default ExcelStyleMaker;