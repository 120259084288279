import React from 'react';
import ResetPassword from '../component/ResetPassword/ResetPassword'
import { Route, Redirect } from 'react-router-dom';
import {isTokenExist} from '../core/authentication'
import qs from 'qs'
//just looking if it login or not
const ResetPasswordRouter = (props) =>{
    const queryString = props.location.search;
    const query = qs.parse(queryString,{ ignoreQueryPrefix: true })
    if (isTokenExist()) {
        return <Route render={props => (
            <Redirect to={`/`} />
        )} />
    }else{
        const guid = query.id;
        return <ResetPassword Guid = {guid} />
    }
}
export default ResetPasswordRouter;