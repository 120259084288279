import React from 'react'
import { Modal } from '@dfds-ui/modal'
import './ErrorLogModal.scss'
import CopyErrorMessage from '../../CopyButton/CopyButton';
const ErrorLogModal = (props) => {
    const { onClose, Title, Show, ErrorMessages, ErrorExplanation } = props;
    return (
        <Modal isOpen={Show} className='ErrorLogModal' onRequestClose={onClose} heading={<span className="headerTitle">{Title}</span>}>
            <span id="Errorlog-Container-Title">{ErrorExplanation}</span>
            <CopyErrorMessage
                CopyText={ErrorMessages}
                className="CopyErrorMessages-Container"
            />
            <div className='Errorlog-Container' >
                {
                    ErrorMessages.map((ErrorMessage, key) => {
                        return <span key={key} id='ErrorLog'>{" - " + ErrorMessage}</span>
                    })
                }
            </div>
        </Modal>
    );
}
export default ErrorLogModal