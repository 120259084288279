import React from 'react'
import { connect } from 'react-redux'
import SignTaskPipeline from '../../../../CommonUI/Pipeline/Pipeline'
import ProjectConfig from '../../../../../config/config';
import { getAuthorization } from '../../../../../core/authentication'
import { ShowHideOnSignModal, clearSignatureDetails } from '../../../../../store/action/signAction'
const OnSignModal = (props) => {
    const { WaitForSign, ErrorMessage, ShowHideOnSignModal, clearSignatureDetails, Signed } = props;
    const AccessToken = localStorage.getItem(`${ProjectConfig.projectName}_access_token`)
    const Role = getAuthorization(AccessToken)
    const OpenTerminal = (Role.toUpperCase() === "ADMIN")
    //
    const PipelineList = [
        {
            Text: (ErrorMessage === "") ? (!Signed ? "Waiting for signature." : "Signed successfully") : ErrorControl(ErrorMessage),
            Status: (ErrorMessage === "") ? (!Signed ? "Waiting" : "done") : "error"
        }]
    return <SignTaskPipeline PipelineList={PipelineList} DoneStatus={Signed} Terminal={OpenTerminal} WaitForResponse={WaitForSign}
        onClose={() => {
            ShowHideOnSignModal(false);
            clearSignatureDetails()
        }} />
}
const ErrorControl = (ErrorMessage) => {
    if (ErrorMessage) {
        const arnicaReturnMessage = " Lütfen e-imza usb'sini çıkartıp, bilgisayarınızı restart edip, usb'yi takarak imzalamayı tekrar deneyiniz."
        const arnicaControlEM = ErrorMessage.includes("Arnica")
        if (arnicaControlEM) {
            return ErrorMessage + arnicaReturnMessage
        }
        const arnicaControlEC = ErrorMessage.errors ? ErrorMessage.errors.code ? ErrorMessage.errors.code.includes("Arnica") : false : false
        if (arnicaControlEC) {
            return ErrorMessage.errors.code + arnicaReturnMessage
        }
    }
    return ErrorMessage && ErrorMessage.errors ? ErrorMessage.errors.code : ErrorMessage
}
const mapStateToProps = state => {
    return {
        WaitForSign: state.sign.WaitForSign,
        SignatureDetails: state.sign.SignatureDetails,
        Signed: state.sign.Signed,
        ErrorMessage: state.sign.Error.ErrorMessage
    }
}
export default connect(mapStateToProps, { ShowHideOnSignModal, clearSignatureDetails })(OnSignModal);