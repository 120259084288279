import React from 'react';
import Voyage from '../component/Voyage/Voyage'
import { connect } from 'react-redux';
import { pageChanged } from '../store/action/pageActions'

const voyage = (props) => {
    const { pageName, pageChanged, match } = props;
    if (pageName !== match.path) {
        pageChanged(match.path)
    }
    return <Voyage />
}
const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(voyage);
