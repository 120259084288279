const voyageTypes = Object.freeze({
    GET_VOYAGES: Symbol('GET_VOYAGES'),
    CLEAR_VOYAGE: Symbol('CLEAR_VOYAGE'),
    FILTER_VOYAGE: Symbol('FILTER_VOYAGE'),
    SET_VOYAGEDATE : Symbol('SET_VOYAGEDATE'),
    CLEAR_VOYAGE_ERROR : Symbol('CLEAR_VOYAGE_ERROR'),
    GETTING_VOYAGES : Symbol('GETTING_VOYAGES'), // for waitforrequest
    VOYAGE_ERROR : Symbol('VOYAGE_ERROR'),
    VOYAGE_CONNECTION_ERROR : Symbol('VOYAGE_CONNECTION_ERROR'),
    SET_VOYAGE_REQUEST_DATA : Symbol('SET_VOYAGE_REQUEST_DATA'),
    SET_VOYAGE_DIRECTION : Symbol('SET_VOYAGE_DIRECTION'),
    SET_VOYAGE_ARRIVAL_PORT : Symbol('SET_VOYAGE_ARRIVAL_PORT'),
    SET_VOYAGE_DEPARTURE_PORT : Symbol('SET_VOYAGE_DEPARTURE_PORT'),
    NO_VOYAGE : Symbol('NO_VOYAGE'),
    GET_VOYAGE_PORTS : Symbol('GET_VOYAGE_PORTS'),
    SET_FIRST_LOAD : Symbol("SET_FIRST_LOAD")
});

export default voyageTypes;