import React from 'react';
import LoginPage from '../component/Login/Login'
import { Route, Redirect } from 'react-router-dom';
import { isTokenExist } from '../core/authentication'
import { connect } from 'react-redux';
import {pageChanged} from '../store/action/pageActions'

const login = (props) => {
    const {pageName,pageChanged,match} = props;
    if(pageName !== match.path)
    {
        pageChanged(match.path)
    }
    if (isTokenExist()) {
        return <Route render={props => (
            <Redirect to={`/`} />
        )} />
    }else{
        return <LoginPage />
    }
}
const mapStateToProps = (state) => {
    return {
        pageName : state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(login);
