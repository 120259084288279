import React, { useEffect, useState } from 'react';
import PerfectTable from '../../../../CommonUI/Table/PerfectTable'
import { HistoryTableSchema } from './HistoryTableSchema'
import { connect } from 'react-redux'
import httpClient from '../../../../../core/httpclient';
import ProjectConfig from '../../../../../config/config';
import './HistoryTable.scss'
import HistoryTableAnimations from '../../../../CommonUI/TableErrorComponents/TableErrorComponents'
import DownloadFile from '../../../../../core/downloadFile'
import { setError } from '../../../../../store/action/declarationAction'


const HistoryTable = (props) => {
    const { Row, setError } = props;
    const [HistoryData, setHistoryData] = useState(null)
    const [WaitForRequest, setWaitForRequest] = useState(false)
    const [ServiceConnection, setServiceConnection] = useState(true)
    const [NoHistory, setNoHistory] = useState(false)
    useEffect(() => {
        if (Row === undefined || Row === null) return;
        const DeclarationId = Row.declarationId
        const EndPoint = `${ProjectConfig.apiUrl}/summarydeclaration/getdisplaydeclarationhistory`
        setWaitForRequest(true)
        httpClient.post(EndPoint, JSON.stringify(DeclarationId)).then(response => {
            setServiceConnection(true)
            const responseHistoryData = response.data.result;
            setHistoryData(responseHistoryData.declarationHistoryItems)
            if (Object.keys(responseHistoryData.declarationHistoryItems).length === 0) {
                setNoHistory(true)
            } else {
                setNoHistory(false)
            }
        }).catch(error => {
            setServiceConnection(false)
        }).finally(() => {
            setWaitForRequest(false)
        })
    }, [Row])
    const onRowClick = (r) => { }
    const onDownloadClick = (selectedRow) => {
        const ApiUrl = `${ProjectConfig.apiUrl}/summarydeclaration/downloads3file`
        const Filename = selectedRow.downloadLink.replace('/', '-')
        const RequestS3 = {
            "key": selectedRow.downloadLink,
            "fileType": selectedRow.fileType
        }
        DownloadFile(ApiUrl, JSON.stringify(RequestS3), Filename).catch((error) => {
            setError("Download Error", error.ErrorCode, error.ErrorDesc)
        })
    }
    const controlButtonsClick = {
        download: onDownloadClick
    }
    const RenderComponents = HistoryTableAnimations(NoHistory,
        !ServiceConnection, WaitForRequest, "History Not Found", "No Connection");
    return (
        <PerfectTable
            bodyClassName={(WaitForRequest ? "tableSpinning" : "")}
            className={" StaticTable "}
            DataArray={HistoryData}
            TableSchema={HistoryTableSchema}
            OnRowClick={onRowClick}
            RowRenderComps={RenderComponents}
            onControlBtnClick={controlButtonsClick}

        />
    );
}
const mapStateToProps = state => {
    return {
        Row: state.modal.Row,
    }
}
export default connect(mapStateToProps, { setError })(HistoryTable);
