export const NavBarConfig = [
    {
        pageName : "/login",
        Items : [
            {
                text : "Login",
                path : "/login",
                active : true
            }
        ]
    },
    {
        pageName : "/voyage",
        Items : [
            {
                text : "Voyage",
                path : "/voyage",
                active : true
            }
        ]
    },
    {
        pageName : "/adminpanel",
        Items : [
            {
                text : "Voyage",
                path : "/voyage",
                active : true
            }
        ]
    },
    {
        pageName : "/declaration",
        Items : [
            // {
            //     text : "Voyage",
            //     path : "/voyage",
            //     selected : false,
            //     active : true
            // },
            {
                text : "Declaration",
                path : "/declaration",
                active : true
            },
        ]
    },
    {
        pageName : "/details",
        Items : [
            // {
            //     text : "Voyage",
            //     path : "/voyage",
            //     selected : false,
            //     active : true
            // },
            // {
            //     text : "Declaration",
            //     path : "/Declaration",
            //     selected : false,
            //     active: true
            // },
            {
                text : "Details",
                path : "/details",
                active : true
            },
            
        ]
    }
]