import React,{useEffect} from 'react'
import { Close, AlertWarning } from '@dfds-ui/icons'
import { Spinner } from '@dfds-ui/react-components'
import ResponseButton from '../SpinButton/SpinButton'

import './Pipeline.scss'
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
const Pipeline = (props) => {
    const { WaitForResponse, PipelineList, DoneStatus, onClose } = props;
    useEffect(()=>{
        if(!WaitForResponse){
            document.getElementById("focusForEscape").focus()
        }
    },[WaitForResponse])
    return (
        <div id="focusForEscape" className="Pipeline-Modal-Container" tabIndex="0" onKeyDown={(event)=>{
            if(!WaitForResponse && event.key==="Escape"){
                typeof onClose === "function" && onClose()
            }
        }}>
            <div className="Pipeline-Modal">
                <div className="Pipeline-Modal-Header">
                    <span id="Pipeline-Modal-Title">Signature Process</span>
                    {
                        !WaitForResponse &&
                        <Close onClick={() => typeof onClose === "function" && onClose()} className="Pipeline-Modal-CloseButton" />
                    }
                </div>
                <div className="Pipeline-Modal-Content">
                    <div className="Pipeline-Warning-Container">
                        <AlertWarning className="Pipeline-Warning-Icon" />
                        <p id="Pipeline-Warning-Message">Caution, don't close to browser it takes 15-30 seconds.</p>
                    </div>
                    <div className="Pipeline-Container">
                        {
                            true ?
                                PipelineList &&
                                PipelineList.map((Pipeline,key) => {
                                    const Status = Pipeline.Status;
                                    const Text = Pipeline.Text;
                                    let statusIcon = <></>
                                    switch (Status.toUpperCase()) {
                                        case "DONE":
                                            statusIcon = <FaRegCheckCircle key={key} className="Pipeline-StatusIcon" />
                                            break;
                                        case "WAITING":
                                            statusIcon = <Spinner key={key} instant={true} className="Pipeline-StatusIcon" />
                                            break;
                                        case "ERROR":
                                            statusIcon = <FaRegTimesCircle key={key} className="Pipeline-StatusIcon" />
                                            break;
                                    }
                                    return (
                                        <div className="Pipeline">
                                            {statusIcon}
                                            <span id="Pipeline-PipeText">{Text}</span>
                                        </div>
                                    )
                                })
                                :
                                <div className="Pipeline-Terminal">
                                    {
                                        PipelineList &&
                                        PipelineList.map((Data,key) => {
                                            return <span key={key} id={"Pipeline-Terminal-Data"} className={"Status-" + Data.Status}>{"- " + Data.Text}</span>
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
                <div className="Pipeline-Modal-Footer">
                    {
                        // !WaitForResponse &&
                        <ResponseButton className={"Pipeline-Modal-ResponseButton " + (!WaitForResponse ? (DoneStatus ? "DONE" : "ERROR") : "")}
                            waitForRequest={WaitForResponse}
                            ButtonLabel={DoneStatus ? "Continue" : "Go back"}
                            onClick={() => typeof onClose === "function" && onClose()} />
                    }

                </div>
            </div>
        </div>
    )
}
export default Pipeline;