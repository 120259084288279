import userTypes from '../type/user';
const initialState = {
    token: null,
    WaitForRequest : false,
    error: {
        ErrorCode: -1,
        ErrorMessage: ""
    },
    Username : ""
}
export default (state = initialState, action) => {
    switch (action.type) {
        case userTypes.USER_AUTHENTICATION_SUCCESS:
            return {
                token: {
                    ...action.token
                },
                error: {
                    ErrorCode: -1,
                    ErrorMessage: ""
                },
                WaitForRequest : false,
                Username : action.Username
            }
        case userTypes.USER_LOGOUT:
            return {
                token: null,
                error: {
                    ErrorCode: -1,
                    ErrorMessage: ""
                },
                WaitForRequest : false,
                Username : ""
            }
        case userTypes.USER_ONLINE:
            return {
                token: {
                    ...action.token
                },
                error: {
                    ErrorCode: -1,
                    ErrorMessage: ""
                },
                WaitForRequest : false,
                Username : action.Username
            }
        case userTypes.USER_WAITFORREQUEST:
            return{
                ...state,
                WaitForRequest : true
            }
        case userTypes.USER_ERROR:
            return{
                ...state,
                error: {
                    ErrorCode : action.ErrorCode,
                    ErrorMessage : action.ErrorMessage
                },
                WaitForRequest : false
            }
        default:
            return state;

    }
}