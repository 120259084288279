import modalTypes from '../type/modal';


const initialState = {
    HistoryModal: false,
    ManualModal: false,
    SignModal: false,
    Row : null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case modalTypes.MODAL_SHOW:
            return Switcher(action, true, state)
        case modalTypes.MODAL_HIDE:
            return Switcher(action, false, state)
        default:
            return state;

    }
}
const Switcher = (action, decision, state) => {
    switch (action.Modal) {
        case "HISTORY_MODAL":
            return {
                ...state,
                HistoryModal: decision,
                Row : action.Row
            }
        case "MANUAL_MODAL":
            return {
                ...state,
                ManualModal: decision,
                Row : action.Row
            }
        case "SIGN_MODAL":
            return {
                ...state,
                SignModal: decision,
                Row : action.Row
            }
    }
}

