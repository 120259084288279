import React from 'react'
import { Modal } from '@dfds-ui/modal'


const DefaultModal = (props) => {
    return (
        <Modal isOpen={true} onRequestClose={props.OnClose} heading={<span className="headerTitle">{props.Title}</span>}>
            <p>{props.Message}</p>
        </Modal>
    );
}
export default DefaultModal;