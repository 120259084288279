import httpClient from "../../core/httpclient";
import declarationTypes from "../type/declaration";
import ProjectConfig from '../../config/config'
import history from "../../core/history";
import XMSErrorHandler from "../../core/xmsErrorHandler";

export const getDeclarations = (VoyageId) => async dispatch => {
    const LocalStorageVoyageId = localStorage.getItem(`${ProjectConfig.projectName}_VoyageId`)
    let LastVoyageId = VoyageId;
    if (VoyageId === "" && LocalStorageVoyageId === "") history.push('/')
    if (VoyageId === "") {
        dispatch({
            type: declarationTypes.SET_REQUEST_VOYAGE_ID,
            VoyageId: LocalStorageVoyageId
        })
        LastVoyageId = LocalStorageVoyageId
    }
    const EndPoint = `${ProjectConfig.apiUrl}/summarydeclaration/getdisplaydeclarations`;
    dispatch({
        type: declarationTypes.GETTING_DECLARATIONS
    })
    httpClient.post(EndPoint, JSON.stringify(LastVoyageId))
        .then(response => {
            const Declarations = response.data.result;
            if (Object.keys(Declarations).length > 0) {
                localStorage.setItem(`${ProjectConfig.projectName}_Vesselname`, Declarations.vesselName)
                localStorage.setItem(`${ProjectConfig.projectName}_Portname`, Declarations.portName)
                dispatch({
                    type: declarationTypes.GET_DECLARATIONS,
                    DeclarationData: Declarations,
                });
            } else {
                dispatch({
                    type: declarationTypes.NO_DECLARATION
                })
            }
        })
        .catch(error => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: declarationTypes.DECLARATION_CONNECTION_ERROR,
                ErrorTitle: "Error",
                ErrorCode: ErrorCode,
                ErrorMessage: ErrorDescription
            });
        })
}
export const clearDeclarationError = () => dispatch => {
    dispatch({
        type: declarationTypes.CLEAR_DECLARATION_ERROR
    })
}
export const setFirstLoad = (Load) => dispatch => {
    dispatch({
        type: declarationTypes.SET_FIRST_LOAD,
        FirstLoad: Load
    })
}
export const setError = (ErrorTitle, ErrorCode, ErrorDesc) => dispatch => {
    dispatch({
        type: declarationTypes.SET_ERROR,
        ErrorTitle: ErrorTitle,
        ErrorCode: ErrorCode,
        ErrorMessage: ErrorDesc
    });
}
export const setVoyageId = (VoyageId) => dispatch => {
    localStorage.setItem(`${ProjectConfig.projectName}_VoyageId`, VoyageId)
    dispatch({
        type: declarationTypes.SET_REQUEST_VOYAGE_ID,
        VoyageId: VoyageId
    })
}
export const UpdateRefreshTime = () => dispatch => {
    dispatch({
        type : declarationTypes.UPDATE_REFRESH_TIME,
    })
}