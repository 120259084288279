export const VoyageRouteTableSchema = {
    ColumnDesign: [
        {
            dataId: "voyageNumber",
            text: "Voyage Number",
        },
        {
            dataId: "vesselName",
            text: "Vessel Name",
        },
        {
            dataId: "loadingPort",
            text: "P.O.L.",
            infoText: "Port of Loading",
        },
        {
            dataId: "unLoadingPort",
            text: "P.O.D.",
            infoText: "Port of Discharging",
        },
        {
            dataId: "scheduleDateTime",
            text: "Departure Date"
        },
        {
            dataId: "expectedArrivalDateTime",
            text: "E.T.A.",
            infoText: "Estimated Time of Arrival",
        }
    ],
    DataDesign: [
        {
            dataId: "voyageNumber",
            // icon : "ship"
        },
        {
            dataId: "vesselName",
            // icon : "ship"
        },
        {
            dataId: "loadingPort",
        },
        {
            dataId: "unLoadingPort",
        },
        {
            dataId: "scheduleDateTime",
            type: "datetime",
        },
        {
            dataId: "expectedArrivalDateTime",
            type: "datetime",
        }
    ]
}
export const DummyVoyageRouteData = [
    {
        voyageNumber: 123456,
        loadingPort: "PEN",
        unLoadingPort: "TRI",
        departureDateTimeEpoch: 1534326784,
        expectedArrivalDateTimeEpoch: 1234567889,
    },
    {
        voyageNumber: 123456,
        loadingPort: "PEN",
        unLoadingPort: "GPA",
        departureDateTimeEpoch: 1534326784,
        expectedArrivalDateTimeEpoch: 1234567889,
    },
    {
        voyageNumber: 123456,
        loadingPort: "PEN",
        unLoadingPort: "AMB",
        departureDateTimeEpoch: 1534326784,
        expectedArrivalDateTimeEpoch: 1234567889,
    }
]