import React from 'react';
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { VoyageRouteTableSchema } from './VoyageRouteTableSchema'
import { connect } from 'react-redux'
import VoyageRouteTableErrorComponents from '../../CommonUI/TableErrorComponents/TableErrorComponents'

import { declarationPageLanguage } from '../../../config/language'
import './VoyageRouteTable.scss'
const language = "en";
const voyageRouteLanguage = declarationPageLanguage[language].voyageRouteLanguage;

const VoyageRouteTable = props => {
    const { DeclarationData, WaitForRequest, DeclarationNotFound, ServiceConnection } = props;
    const DeclarationNotFoundMessage = "Voyage Route Not Found"
    const NoConnectionMessage = "No Connection"
    const RenderComponents = VoyageRouteTableErrorComponents(DeclarationNotFound,
        false, WaitForRequest, DeclarationNotFoundMessage, NoConnectionMessage);
    return (
        <PerfectTable
            bodyClassName={(WaitForRequest ? "tableSpinning" : "")}
            className={" InfoTable "}
            DataArray={DeclarationData && DeclarationData.voyageList}
            TableSchema={VoyageRouteTableSchema}
            RowRenderComps={RenderComponents}
        />
    );
}

const mapsToProps = (state) => {
    return {
        DeclarationData: state.declaration.DeclarationData,
        WaitForRequest: state.declaration.WaitForRequest,
        DeclarationNotFound: state.declaration.DeclarationNotFound,
        ServiceConnection: state.declaration.ServiceConnection
    }
}
export default connect(mapsToProps, null)(VoyageRouteTable);