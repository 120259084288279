import React from 'react';
import Footer from './Footer/Footer'
import FootLine from './Footer/FootLine'

import SDRouter from '../RoutedComponents/Routes/index'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header/Header'
import HeadLine from './Header/HeadLine'

import { NavBarItem } from '@dfds-ui/react-components';

const App = () => {
    return (
        <>
            <HeadLine />
            <div className="App">
                <Header />
                <SDRouter />
                <Footer />
            </div>
            <FootLine />
        </>
    );
}

export default App;