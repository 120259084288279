import detailType from '../type/detail';

const initialState = {
    DetailsData: null,
    FilterText: null,
    SelectedBooking: null,
    Error: {
        ErrorCode: -1,
        ErrorTitle: "",
        ErrorMessage: ""
    },
    WaitForRequest: false,
    DetailNotFound: false,
    ServiceConnection: true,
    RefreshTime: `${("0" + (new Date().getHours()).toString()).slice(-2)}.${("0" + (new Date().getMinutes()).toString()).slice(-2)}`
    // When you want to hours from date it'would be 1 digits 
    //for example 0.2 i convert it to 2 digits (00.02) 0.2 -> 00.02
}
export default (state = initialState, action) => {
    switch (action.type) {
        case detailType.GET_VOYAGE_DETAILS:
            return {
                ...state,
                DetailsData: {
                    ...action.DetailsData
                },
                FilterText : null,
                WaitForRequest: false,
                DetailNotFound: false,
                ServiceConnection: true
            }
        case detailType.CLEAR_VOYAGE_DETAILS:
            return {
                ...state,
                DetailsData: null,
                FilterText: null,
                Error: {
                    ErrorCode: -1,
                    ErrorDesc: ""
                },
                WaitForRequest: false,
                DetailNotFound: false,
                ServiceConnection: true,
                SelectedBooking: null
            }
        case detailType.DETAILS_CONNECTION_ERROR:
            return {
                ...state,
                Error: {
                    ErrorCode: -1,
                    ErrorDesc: ""
                }
            }
        case detailType.NO_VOYAGE_DETAILS:
            return {
                ...state,
                DetailsData: null,
                FilterText: null,
                DetailNotFound: true,
                WaitForRequest: false,
                ServiceConnection: true
            }
        case detailType.DETAILS_CONNECTION_ERROR:
            return {
                ...state,
                DetailsData: null,
                FilterText: null,
                WaitForRequest: false,
                DetailNotFound: false,
                ServiceConnection: false,
                Error: {
                    ErrorCode: action.ErrorCode,
                    ErrorTitle: action.ErrorTitle,
                    ErrorMessage: action.ErrorMessage
                }
            }
        case detailType.GETTING_DETAILS:
            return {
                ...state,
                WaitForRequest: true,
                DetailNotFound: false,
                ServiceConnection: true
            }
        case detailType.CLEAR_DETAILS_ERROR:
            return {
                ...state,
                VoyageId: action.VoyageId
            }
        case detailType.SET_SELECTED_BOOKING:
            return {
                ...state,
                SelectedBooking: action.SelectedBooking
            }
        case detailType.FILTER_DATA:
            return{
                ...state,
                FilterText : action.FilterText,
            }
        default:
            return state;
    }

}