import React from 'react'
import { IoMdCopy } from "react-icons/io";
import './CopyButton.scss'
import PropTypes from 'prop-types';

export default class CopyButton extends React.Component {
    constructor() {
        super()
        this.state = { ShowToolTip: false, TooltipText: "Copy to clipboard" }
    }
    onCopyButtonClick = (e) => {
        // create hidden text element, if it doesn't already exist
        var targetId = "_hiddenCopyText_";
        // must use a temporary form element for the selection and copy
        target = document.getElementById(targetId);
        if (!target) {
            var target = document.createElement("textarea");
            target.style.position = "absolute";
            target.style.left = "-9999px";
            target.style.top = "0";
            target.id = targetId;
            document.body.appendChild(target);
        }

        if (typeof (this.props.CopyText) === "object") //checking array actualy weird js things...
        {
            let mergeText = ""
            this.props.CopyText.map((Text) => {
                mergeText = mergeText + Text + "\n"
            })
            target.textContent = mergeText;

        } else {
            target.textContent = this.props.CopyText;
        }

        // select the content
        var currentFocus = document.activeElement;
        target.focus();
        target.setSelectionRange(0, target.value.length);

        // copy the selection
        var succeed;
        try {
            succeed = document.execCommand("copy");
        } catch (e) {
            succeed = false;
        }
        // restore original focus
        if (currentFocus && typeof currentFocus.focus === "function") {
            currentFocus.focus();
        }
        // clear temporary content
        target.textContent = "";
        if (succeed) {
            this.setState({ TooltipText: "Copied" })
        } else {
            this.setState({ TooltipText: "Couldn't copy" })
        }
    }
    onCopyButtonHover = (e) => {
        this.setState({ ShowToolTip: true })
    }
    onCopyButtonMouseLeave = (e) => {
        this.setState({ ShowToolTip: false, TooltipText: "Copy to clipboard" })
    }
    shouldComponentUpdate(nextProps, nextState) {
        // if(nextProps)
        return true
    }
    render() {
        return (
            <div onMouseEnter={(e) => { this.onCopyButtonHover(e) }}
                onMouseLeave={(e) => { this.onCopyButtonMouseLeave(e) }}
                onClick={(e) => this.onCopyButtonClick(e)}
                className={"CopyButton-Container" + (this.props.className ? (" " + this.props.className) : "")}>
                <IoMdCopy id="CopyButton-CopyIcon" />
                <span id="CopyButton-Tooltip">
                    {this.state.TooltipText}
                </span>
            </div>
        )
    }
}
CopyButton.propTypes = {
    className: PropTypes.string,
    CopyText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
}