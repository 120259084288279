export const JustNumber = (e) => {
    const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-', "Backspace", "Delete", "ArrowLeft", "ArrowRight"]
    if (keys.indexOf(e.key) > -1 || (e.ctrlKey && (e.key === "A" || e.key === "a"))) {
        var value = e.target.value ? e.target.value : ""
        const negativeKey = e.key === "-"
        const dotKey = e.key === "."
        if (value.includes("-") && negativeKey) {
            e.preventDefault()
            return
        }
        if (negativeKey) {
            e.target.value = "-" + e.target.value
            e.preventDefault()
            return
        }
        if (value.includes(".") && dotKey) {
            e.preventDefault()
            return
        }
    } else {
        e.preventDefault()
        return
    }
}