import React from 'react'
import ReactDOM from 'react-dom'
export default class JustPortal extends React.Component {
    render() {
        return null
    }
    componentDidMount() {
        var p = this.props.portalId && document.getElementById(this.props.portalId);
        if (!p) {
            p = document.createElement('div')
            p.id = this.props.portalId
            document.body.appendChild(p)
        }
        this.portalElement = p;
        this.componentDidUpdate()
    }
    componentWillUnmount() {
        if (document.body.contains(this.portalElement)) {
            document.body.removeChild(this.portalElement)
            delete this.portalElement
        } else {
            delete this.portalElement
        }
    }
    componentDidUpdate() {
        ReactDOM.render(this.props.children, this.portalElement)
    }

}