import React from 'react';
import AdminPanel from '../component/AdminPanel/AdminPanel'
import { pageChanged } from '../store/action/pageActions';
import { connect } from 'react-redux'

//Dummy for now just routing
const adminpanel = (props) => {
    const { pageChanged } = props
    pageChanged("/adminpanel")
    return <AdminPanel />
}
export default connect(null, { pageChanged })(adminpanel);