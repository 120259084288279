import React from 'react'
import RowCell from './RowCell/RowCell'
import './JustRow.scss'
import PropTypes, { instanceOf } from 'prop-types';
import { getDataFromParent, getDataListFromParent } from '../../Helper/ObjectTree'
import RowLocker from '../../Helper/RowLocker';
import { deepCloneArray } from '../../../../../core/cloneArray';
import { classNameSelector } from '../../Helper/ClassnameSelector';
export default class TableRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            RowSelected: ((props.RowIndex === 0) ? true : false), RowUpdated: false,
            selfRowData: props.RowData, forceRender: false, RowChecked: false, DataHistoryList: this._onInitializeHistoryList(props.RowData)
        }
    }
    _onInitializeHistoryList = (RowData) => {
        let HistoryList = []
        if (!RowData) return []
        for (const [key, value] of Object.entries(RowData)) {
            let historyObject = {}
            const dataDesign = this.props.DataDesign
            if (value && typeof value === "object" && !Array.isArray(value)) {
                const childList = this._onInitializeHistoryList(value)
                if (childList && childList.length > 0) {
                    childList.map((childObject) => {
                        HistoryList.push(childObject)
                    })

                }
            } else {
                for (let index = 0; index < dataDesign.length; index++) {
                    if (dataDesign[index].columnId === key && (dataDesign[index].updatable || dataDesign[index].input)) {
                        historyObject.ColumnId = key
                        historyObject.CellHistoryList = [value]
                        HistoryList.push(historyObject)
                        break
                    }
                }

            }

        }
        return HistoryList
    }
    _onRowUpdate = (update, newRow, afterUpdate) => {
        this.setState({ ...this.state, RowUpdated: update, selfRowData: newRow, forceRender: !this.state.forceRender }, () => {
            if (afterUpdate && typeof afterUpdate === "function") {
                afterUpdate()
            }
        })
    }
    _setDataHistoryList = (columnId, dataCellHistoryList, callBack) => {
        this.setState({ DataHistoryList: this._findColumnReturnListOfHistory(columnId, dataCellHistoryList) }, callBack)
    }
    _findColumnReturnListOfHistory = (columnId, dataCellHistoryList) => {
        return this.state.DataHistoryList.map((history) => {
            if (history.ColumnId === columnId) {
                history.CellHistoryList = dataCellHistoryList
            }
            return history
        })
    }
    _onRowChange = (newRow) => {
        this.setState({ ...this.state, selfRowData: newRow, forceRender: !this.state.forceRender })
    }
    _onRowFocus = (e, onRowClick) => {
        if (!this.state.RowSelected) {
            onRowClick(e, this.state.selfRowData)
        }
        if (e.beforeTarget !== undefined) {
            e.beforeTarget.changeState(false)
            delete e.beforeTarget.changeState
            delete e.beforeTarget
        }
        this.setState({ RowSelected: true })
    }
    _onRowBlur = (e) => {
        const currentTarget = e.currentTarget;
        const willTarget = e.relatedTarget;
        if (willTarget) {
            if (willTarget.parentElement === currentTarget) // If row's cell targetted it will not effect to selected row.
                return
            if (willTarget.offsetParent !== currentTarget.offsetParent) // if any element but not inside of table
            {
                e.beforeTarget = currentTarget; // for clear target before onfocus
                e.beforeTarget.changeState = (Select) => this.setState({ RowSelected: Select })
                return
            }
            this.setState({ RowSelected: false })
        } else {
            e.beforeTarget = currentTarget; // for clear target before onfocus
            e.beforeTarget.changeState = (Select) => this.setState({ RowSelected: Select })
        }
    }
    componentDidMount() {
        if (this.props.RowIndex === 0) {
            this.rowSelf.focus()
            this.rowSelf.cells[0].focus()
            this.props.onRowClick(null, this.state.selfRowData)
        }
    }
    checkContextMenuChange = (nextContextMenu, prevContextMenu) => {
        if (Array.isArray(nextContextMenu)) {
            if (!Array.isArray(prevContextMenu)) return true
            if (nextContextMenu.length !== prevContextMenu.length) return true
            if (nextContextMenu.length > 0 && prevContextMenu.length > 0) {
                if (nextContextMenu[0].Text !== prevContextMenu[0].Text)
                    return true
                if (nextContextMenu[0].Icon !== prevContextMenu[0].Icon)
                    return true
                if ((nextContextMenu[0].ActiveCheck && prevContextMenu[0].ActiveCheck))
                    if (nextContextMenu[0].ActiveCheck !== prevContextMenu[0].ActiveCheck)
                        return true
            } else if (nextContextMenu.length > 0 && prevContextMenu.length < 1) {
                return true
            } else if (nextContextMenu.length < 1 && prevContextMenu > 0) {
                return true
            }
        }
        return false
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.RowData !== this.props.RowData) { //ascending and descending colons (colon order)
            const dataHistory = this.props.DataHistory
            const rowId = this.props.RowId
            let rowData = nextProps.RowData
            let rowUpdated = this.state.RowUpdated
            let dataHistoryList = null
            if (dataHistory && dataHistory.length > 0) {
                for (let index = 0; index < dataHistory.length; index++) {
                    const H = dataHistory[index]
                    if (H.rowId === nextProps.RowData[rowId]) {
                        dataHistoryList = deepCloneArray(this.state.DataHistoryList)
                        rowData = H.row
                        rowUpdated = true
                        if (H.cells && H.cells.length > 0) {
                            for (let cellIndex = 0; cellIndex < H.cells.length; cellIndex++) {
                                for (let historyIndex = 0; historyIndex < dataHistoryList.length; historyIndex++) {
                                    if (H.cells[cellIndex].columnId === dataHistoryList[historyIndex].ColumnId) {
                                        dataHistoryList[historyIndex].CellHistoryList = H.cells[cellIndex].dataList
                                        break;
                                    }
                                }
                            }
                        }
                        break
                    }
                    if ((dataHistory.length - 1) === index) {
                        rowUpdated = false
                        break
                    }
                }
            }
            if (dataHistoryList === null) dataHistoryList = this._onInitializeHistoryList(nextProps.RowData)
            this.setState({ ...this.state, RowUpdated: rowUpdated, selfRowData: rowData, DataHistoryList: dataHistoryList })
            return false
        }
        if (nextState.selfRowData !== this.state.selfRowData) {
            if (this.checkSelected(nextProps.RowId, nextState.selfRowData, nextProps.DataSelected) !== this.checkSelected(this.props.RowId, this.state.selfRowData, this.state.DataSelected)) {
                this.setState({ RowChecked: true })
                // if (nextState.RowChecked === false) return false
            } else {
                this.setState({ RowChecked: false })
                // if (nextState.RowChecked === true) return false
            }
            return true;
        }
        if (nextState.RowSelected !== this.state.RowSelected) {
            return true
        }
        if (nextState.RowUpdated !== this.state.RowUpdated) {
            return true
        }
        if (this.checkContextMenuChange(nextProps.RowContextMenu, this.props.RowContextMenu)) {
            return true
        }
        if (this.state.forceRender !== nextState.forceRender) {
            return true
        }
        if (this.state.RowChecked !== nextState.RowChecked) {
            return true
        }
        if (this.props.DataSelected !== nextProps.DataSelected) {
            if (this.checkSelected(nextProps.RowId, nextState.selfRowData, nextProps.DataSelected) !== this.checkSelected(this.props.RowId, this.state.selfRowData, this.state.DataSelected)) {
                this.setState({ ...this.state, RowChecked: true })
            } else {
                this.setState({ ...this.state, RowChecked: false })
            }
            return false
        }
        return false;
    }
    checkSelected = (rowId, rowData, selectedRows) => {
        if (!rowId) return false
        if (!rowData[rowId]) return false
        if (!selectedRows) return false
        if (!Array.isArray(selectedRows)) return false
        if (selectedRows.length < 1) return false
        return selectedRows.filter((R) => R[rowId] === rowData[rowId]).length > 0
    }
    _getHistoryListForCell = (columnId) => {
        let historyList
        this.state.DataHistoryList.filter((DHL) => DHL.ColumnId === columnId).map((DHL) => historyList = DHL.CellHistoryList)
        return historyList ? historyList : []
    }
    render() {
        const dataDesign = this.props.DataDesign
        const rowData = this.state.selfRowData
        const columnDesigns = this.props.ColumnDesigns
        const rowIndex = this.props.RowIndex
        const rowSelected = this.state.RowSelected
        const _onRowClick = this.props.onRowClick
        const rowId = rowData ? rowData[this.props.RowId] : null
        const rowLock = this.props.RowLock ? this.props.RowLock : null
        const rowLocked = RowLocker(rowLock, rowData)
        return (
            <tr
                // onClick={(e) => _onRowClick(e, RowData)} focus also means click actually most of case we need focus for my project i need focus at least
                //I should separate after
                ref={(row) => this.rowSelf = row}
                onFocus={(e) => this._onRowFocus(e, _onRowClick)}
                onBlur={(e) => this._onRowBlur(e)}
                className={"JustTable-TableRow" +
                    (rowLocked ? " TableRow-Locked" : "") +
                    (this.state.RowUpdated ? " TableRow-Updated" : "") +
                    (rowSelected ? " TableRow-RowSelected" : "") +
                    (" " + classNameSelector(columnDesigns, rowData))}>
                {
                    dataDesign.map((design, key) => {
                        if (design.hide) return <td style={{ display: "none" }} key={key} />
                        const columnDesign = columnDesigns[key]
                        const parentIds = columnDesign.parentIds
                        return <RowCell
                            RowIndex={rowIndex}
                            ColumnDesigns={columnDesigns}
                            DesignIndex={key}
                            key={key}
                            Data={getDataFromParent(parentIds, rowData, design.columnId, columnDesigns[key].parentArrayPosition)}
                            HistoryDataList={this._getHistoryListForCell(design.columnId)}//{getDataListFromParent(parentIds, this.state.DataHistoryList, design.columnId)}
                            // onDataListChange = {set}
                            ParentIds={parentIds}
                            DataDesign={design}
                            RowContextMenu={this.props.RowContextMenu}
                            RowContextActions={this.props.RowContextActions}
                            onRowUpdate={this._onRowUpdate}
                            SetRow={this._onRowChange}
                            RowData={rowData}
                            rowSelected={this.state.RowChecked}
                            RowLock={rowLocked}
                            DataHistoryListRegister={this._setDataHistoryList}
                            RowId={rowId ? rowId : (key + (rowIndex * columnDesigns.length) + 1)} // If doesnt have uniqueid it would count till end
                        />
                    })
                }
            </tr>
        )
    }
}
TableRow.propTypes = {
    DataDesign: PropTypes.array,
    RowData: PropTypes.object,
    ColumnDesigns: PropTypes.array,
    rowIndex: PropTypes.number,
    onRowClick: PropTypes.func,
}
TableRow.defaultProps = {
    DataDesign: [],
    RowData: {},
    ColumnDesigns: [],
    rowIndex: 0,
    onRowClick: () => { }
}