import React, { useEffect, useState } from 'react';
import { Modal } from '@dfds-ui/modal';
import { connect } from 'react-redux';
import { modalHide } from '../../../../store/action/modalActions'
import XMLViewer from 'react-xml-viewer'
import './SignModal.scss'
import UserInput from '../../../CommonUI/UserInput/UserInput';
import { GiCombinationLock } from "react-icons/gi";
import httpClient from '../../../../core/httpclient';
import ProjectConfig from '../../../../config/config';
import PinSignButton from '../../../CommonUI/SpinButton/SpinButton'
import OnSignModal from './OnSignModal/OnSign'
import XMSErrorHandler from '../../../../core/xmsErrorHandler';
import { setPinCode, ConnectToLocalService, clearSign, getUsers, setSelectedUser } from '../../../../store/action/signAction'
import { getDeclarations, UpdateRefreshTime } from '../../../../store/action/declarationAction';
import { Select } from '@dfds-ui/react-components';
const SignModal = (props) => {
    const { SignModal, modalHide, VoyageId, Row,
        setPinCode, PinCode, WaitForSign, getUsers,
        ConnectToLocalService, getDeclarations, UpdateRefreshTime,
        Signed, OnSignModalShow, Users, setSelectedUser, SelectedUser, clearSign } = props;
    const [XML, setXML] = useState("")
    const PreviewXMLUrl = `${ProjectConfig.apiUrl}/summarydeclaration/downloaddeparturesummarydeclarationfile`
    const [XMLMessage, setXMLMessage] = useState("Waiting for XML Document.")
    const [ButtonText, setButtonText] = useState("Sign")
    const [OnSignModalShow_Timer, setOnSignModalShow_Timer] = useState(false)
    const OnSignModalWaitTime = 250;
    const getXMLFromApi = () => {
        if (VoyageId === "" || !SignModal) return
        setXMLMessage("Waiting for XML Document.")
        httpClient.post(PreviewXMLUrl, JSON.stringify(VoyageId)).then((response) => {
            const XMLFromApi = response.data
            setXML(XMLFromApi)
        }).catch(error => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            setXMLMessage("Error : " + ErrorDescription)
        })
    }

    useEffect(() => {
        if (SignModal) {
            getXMLFromApi()
            getUsers()
        } else {
            setXML("")
            setXMLMessage("")
        }
    }, [SignModal])
    useEffect(() => {
        getXMLFromApi()
    }, [VoyageId])
    useEffect(() => {
        if (WaitForSign) {
            setButtonText("")
            // intervalId = setInterval(CheckLocalService,2000)
        } else if (!WaitForSign && !Signed) {
            setButtonText("Sign")
            // clearInterval(intervalId)
        }
    }, [WaitForSign])
    useEffect(() => {
        if (Signed) {
            setButtonText("Signed")
        }
    }, [Signed])
    useEffect(() => {
        if (OnSignModalShow) {
            setTimeout(() => setOnSignModalShow_Timer(true), OnSignModalWaitTime)
        } else {
            setOnSignModalShow_Timer(false)
            // setTimeout(()=>setOnSignModalShow_Timer(false),OnSignModalWaitTime)
        }
    }, [OnSignModalShow])
    const signXml = () => {
        if (PinCode.trim() === "") return; //NO pincode
        if (XML.trim() === "") return; //NO xml preview
        if (WaitForSign) return; // It's already in process
        if (Signed) return; // It's done.
        if (SelectedUser.trim() === "") return
        const DeclarationId = Row.declarationId;
        ConnectToLocalService(DeclarationId, PinCode, SelectedUser)
    }
    // 
    return (
        OnSignModalShow_Timer ? <OnSignModal /> :
            <Modal className="signModal" isOpen={SignModal} heading="Sign" onRequestClose={() => {
                modalHide({ Modal: "SIGN_MODAL" })
                if (Signed) {
                    getDeclarations(VoyageId)
                    UpdateRefreshTime()
                }
                clearSign()
                setButtonText("Sign")
            }
            }>
                {
                    XML !== "" &&
                    <div className="UserSelection">
                        <span id="SelectSignUser">Select Sign User : </span>
                        <Select
                            className="userBox"
                            name="select"
                            size="small"
                            onChange={(event) => {
                                const SelectedUser = event.target.value
                                setSelectedUser(SelectedUser)
                            }}

                        >
                            <option disabled selected> Select User </option>
                            {
                                Users &&
                                Users.map((User) =>
                                    User.username === SelectedUser ?
                                        <option selected>{User.username}</option>
                                        :
                                        <option>{User.username}</option>
                                )
                            }
                        </Select>
                    </div>
                }
                <div className="xmlViewer">
                    {
                        XML !== "" ?
                            <XMLViewer xml={XML} /> :
                            <h5 id="XMLWait">{XMLMessage}</h5>
                    }
                </div>
                {
                    XML !== "" &&
                    <div className="signActions">
                        <UserInput
                            className="pinCodeInput"
                            name='pinCode'
                            id='pinCode'
                            type='password'
                            isValid={true}
                            onChange={(event) => {
                                const userPinCode = event.target.value;
                                setPinCode(userPinCode)
                            }}
                            onKeyPress={(event) => {
                                const key = event.key;
                                if (key === "Enter") {
                                    signXml()
                                }
                            }}
                            imgComp={<GiCombinationLock />}
                            holder="PIN CODE"
                            disabled={Signed}
                        />
                        <PinSignButton
                            onClick={() => signXml()}
                            className={Signed ? "pinSignButton" : "pinSignButton SIGNED"}
                            id={((PinCode !== "" && SelectedUser !== "") && !WaitForSign) ? "Clickable" : "NotClickable"}
                            waitForRequest={WaitForSign}
                            ButtonLabel={ButtonText}
                        />
                    </div>
                }
            </Modal>

    )
}
const mapStateToProps = state => {
    return {
        SignModal: state.modal.SignModal,
        Row: state.modal.Row,
        VoyageId: state.declaration.VoyageId,
        Signed: state.sign.Signed,
        WaitForSign: state.sign.WaitForSign,
        PinCode: state.sign.PinCode,
        OnSignModalShow: state.sign.OnSignModalShow,
        Users: state.sign.Users,
        SelectedUser: state.sign.SelectedUser
    }
}
export default connect(mapStateToProps, {
    modalHide, setPinCode, UpdateRefreshTime, getUsers, setSelectedUser
    , ConnectToLocalService, getDeclarations, clearSign
})(SignModal);