import React from 'react'
import './JustHeader.scss'
import HeaderCell from './HeaderCell/HeaderCell'
import PropTypes from 'prop-types';

export default class TableHeader extends React.Component {
    shouldComponentUpdate(nextProps, nextStates) {
        if (this.props.ColumnDesigns !== nextProps.ColumnDesigns) {
            return true
        }
        return false;
    }
    render() {
        const columnDesigns = this.props.ColumnDesigns;
        const _onHeaderRowClick = this.props.onHeaderRowClick
        const _onHeaderCellClick = this.props.onHeaderCellClick
        return (
            <tr onClick={(e) => _onHeaderRowClick(e)} className="JustStaticTable-TableHeader">
                {
                    columnDesigns.map((columnDesign, index) => {
                        if (!columnDesigns.hide) {
                            return <HeaderCell
                                onHeaderCellClick={_onHeaderCellClick}
                                key={index}
                                designIndex={index}
                                ColumnDesigns={columnDesigns}
                                ColumnDesign={columnDesign} />
                        } else {
                            return <th style={{ display: "none" }} key={index}></th>
                        }
                    })
                }
            </tr>
        )
    }
}

TableHeader.propTypes = {
    columnDesigns: PropTypes.array,
    onHeaderRowClick: PropTypes.func,
    onHeaderCellClick: PropTypes.func
}
TableHeader.defaultProps = {
    columnDesigns: [],
    onHeaderRowClick: () => { },
    onHeaderCellClick: () => { }
}