import voyageType from '../type/voyage';
import { decreaseDate, increaseDate } from '../../core/dateStr'
const initialState = {
    VoyageData: null,
    VoyageRequestData: {
        ScheduleDateBegin: decreaseDate(3),
        ScheduleDateEnd: increaseDate(1),
        LoadingPort: "",
        UnloadingPort: "",
        Direction: ""
    },
    Error: {
        ErrorCode: -1,
        ErrorTitle: "",
        ErrorMessage: ""
    },
    WaitForRequest: false,
    VoyageNotFound: false,
    ServiceConnection: true,
    VoyagePorts: null,
    FirstLoad: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case voyageType.GET_VOYAGES:
            return {
                ...state,
                VoyageData: {
                    ...action.VoyageData
                },
                WaitForRequest: false,
                VoyageNotFound: false,
                ServiceConnection: true,
                FirstLoad: false
            }
        case voyageType.GET_VOYAGE_PORTS: {
            return {
                ...state,
                VoyagePorts: action.Ports
            }
        }
        case voyageType.SET_VOYAGEDATE:
            return {
                ...state,
                VoyageRequestData: {
                    ...state.VoyageRequestData,
                    ScheduleDateBegin: action.ScheduleDateBegin,
                    ScheduleDateEnd: action.ScheduleDateEnd
                }
            }
        case voyageType.SET_FIRST_LOAD:
            return {
                ...state,
                FirstLoad: action.FirstLoad
            }
        case voyageType.SET_VOYAGE_DIRECTION:
            return {
                ...state,
                VoyageRequestData: {
                    ...state.VoyageRequestData,
                    Direction: action.Direction
                }
            }
        case voyageType.SET_VOYAGE_REQUEST_DATA:
            return {
                ...state,
                VoyageRequestData: action.VoyageRequestData
            }
        case voyageType.SET_VOYAGE_ARRIVAL_PORT:
            return {
                ...state,
                VoyageRequestData: {
                    ...state.VoyageRequestData,
                    UnloadingPort: action.ArrivalPort
                }
            }
        case voyageType.SET_VOYAGE_DEPARTURE_PORT:
            return {
                ...state,
                VoyageRequestData: {
                    ...state.VoyageRequestData,
                    LoadingPort: action.DeparturePort
                }
            }
        case voyageType.CLEAR_VOYAGE:
            return {
                VoyageData: null,
                VoyageRequestData: {
                    ScheduleDateBegin: decreaseDate(3),
                    ScheduleDateEnd: increaseDate(1),
                    LoadingPort: "",
                    UnloadingPort: "",
                    Direction: ""
                },
                Error: {
                    ErrorCode: -1,
                    ErrorTitle: "",
                    ErrorMessage: ""
                },
                WaitForRequest: false,
                VoyageNotFound: false,
                ServiceConnection: true,
                VoyagePorts: null,
                FirstLoad : true
            }
        case voyageType.NO_VOYAGE:
            return {
                ...state,
                VoyageData: null,
                VoyageNotFound: true,
                WaitForRequest: false,
                ServiceConnection: true
            }
        case voyageType.CLEAR_VOYAGE_ERROR:
            return {
                ...state,
                Error: {
                    ErrorCode: -1,
                    ErrorTitle: "",
                    ErrorMessage: ""
                }
            }
        case voyageType.GETTING_VOYAGES:
            return {
                ...state,
                WaitForRequest: true,
                VoyageNotFound: false,
                ServiceConnection: true
            }
        case voyageType.VOYAGE_ERROR:
            return {
                ...state,
                Error: {
                    ErrorCode: action.ErrorCode,
                    ErrorTitle: action.ErrorTitle,
                    ErrorMessage: action.ErrorMessage
                }
            }
        case voyageType.VOYAGE_CONNECTION_ERROR:
            return {
                ...state,
                VoyageData: null,
                WaitForRequest: false,
                VoyageNotFound: false,
                ServiceConnection: false,
                Error: {
                    ErrorCode: action.ErrorCode,
                    ErrorTitle: action.ErrorTitle,
                    ErrorMessage: action.ErrorMessage
                }
            }
        case voyageType.SET_VOYAGE_REQUEST_DATA: {
            return {
                ...state,
                VoyageRequestData: action.VoyageRequestData
            }
        }
        default:
            return state;

    }
}

