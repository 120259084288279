import pageTypes from '../type/page';


const initialState = {
    pageName: ""
}
export default (state = initialState, action) => {
    switch (action.type) {
        case pageTypes.PAGE_CHANGED:
            return{
                ...state,
                pageName : action.pageName
            }
        default:
            return state;

    }
}

