import React from 'react';
import { connect } from 'react-redux';
import VoyageTable from './VoyageTable/VoyageTable'
import VoyageTableHeader from './VoyageTable/VoyageTableHeader'
import { ErrorModal } from '../CommonUI/Modals/ErrorModal'
import './Voyage.scss'
import {clearVoyageError} from '../../store/action/voyageAction'


import { voyagePageLanguage } from '../../config/language';
const language = "en";

const Voyage = props => {
    const {ErrorMessage,ErrorTitle,ErrorCode,clearVoyageError} = props;
    return (
        <>
            <div className="voyageTableContainer">
                {/* <h2 className="pageTitle">{voyagePageLanguage[language].VoyageHeader}</h2> */}
                <VoyageTableHeader />
                <VoyageTable />
            </div>
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ErrorTitle={ErrorTitle}
                    ErrorMessage={ErrorMessage}
                    OnClose={() => clearVoyageError()}
                />
            }
        </>
    );
}
const mapStateToProps = state => {
    return {
        ErrorMessage: state.voyage.Error.ErrorMessage,
        ErrorTitle : state.voyage.Error.ErrorTitle,
        ErrorCode : state.voyage.Error.ErrorCode
    }
}
export default connect(mapStateToProps, {clearVoyageError})(Voyage);