
const commonLanguage = {
    tr:{

    },
    en:{
        ApplicationName : "Custom Summary Declaration Login",
        ValidationMessage : "This field is required.",
        NoConnection : "No Connection."
    }
}
export const networkErrors = {
    tr :{

    },
    en :{
        InvalidUser : "Incorrect username or password."
    }
}
export const declarationPageLanguage = {
    tr: {

    },
    en: {
        NoConnectionMessage : commonLanguage.en.NoConnection,
        voyageRouteLanguage : {
            NoConnectionMessage : commonLanguage.en.NoConnection
        }
    }
}
export const voyagePageLanguage = {
    tr: {

    },
    en: {
        NoVoyageMessage : "No voyages found.",
        NoConnectionMessage : commonLanguage.en.NoConnection,
        StartDate : "Start Date",
        EndDate : "EndDate",
        VoyageHeader : "VOYAGES",
        VoyageValidationError : "Check date please. You cannot arrival before departure.",
        VoyageTimeValidationError : "You cannot search longer than 30 days",
    }
}
export const loginPageLanguage = {
    tr: {
        ValidationMessage : "Bu alan doldurulmak zorundadır.",

    },
    en: {
        ValidationMessage : commonLanguage.en.ValidationMessage,
        LoginHeader : commonLanguage.en.ApplicationName,
        SignInButtonText : "SIGN IN",
        ForgotPassword : "Forgot Password?",
        UserNameHolder : "Username",
        PasswordHolder : "Password"

    }
}
export const NoAuthLanguage = {
    tr:{

    },
    en: {
        NoAuthTitle : "Permission Error",
        NoAuthMessage : "It seems you don't have an access to see this page.",
        NoAuthButtonLabel : "Go Back"
    }
}
export const forgotPasswordLanguage = {
    tr:{

    },
    en:{
        ForgotHeader: "Forgot Password",
        SuccessMessage : "Password reset information sent to your email.",
        ErrorMessage : "", // Gelen error'u aldığı gibi basıyor.
        ValidationMessage : commonLanguage.en.ValidationMessage,
        ButtonText : "SEND",
        UserNameHolder : "Username"
    }
}
export const resetPasswordLanguage = {
    tr:{

    },
    en:{
        ValidationMessage : commonLanguage.en.ValidationMessage,
        ButtonText : "RESET",
        ResetHeader : "Reset Password",
        SuccessMessage : "",
        ErrorMessage : "",
        ConfirmPasswordHolder : "Confirm Password",
        NewPasswordHolder : "New Password",
        InputErrorTitle : "Error",
        InputErrorMessage : "Password fields aren't same. Check your password.",
        NetworkErrorTitle : "Error",
        NetworkErrorMessage: "Something went wrong. Try again.",
        SuccessTitle : "Success",
        SuccessMessage : "Your password changed."
    }
}

