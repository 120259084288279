import React from 'react';
import './ErrorMessage.scss';

const errorMessage = React.memo(props => {
    return (
        props.ErrorMessage &&
        <div className="errorMessage">
            {props.ErrorMessage}
        </div>
    );
})
export default errorMessage;