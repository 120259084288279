import React, { useEffect,useState } from 'react';
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { VoyageTableSchema } from './VoyageTableSchema'
import VoyageTableErrorComponents from '../../CommonUI/TableErrorComponents/TableErrorComponents'
import { connect } from 'react-redux';
import { getVoyages, setFirstLoad } from '../../../store/action/voyageAction'
import { setVoyageId } from '../../../store/action/declarationAction'
import { voyagePageLanguage } from '../../../config/language'
import VoyageErrorLogs from '../../CommonUI/Modals/ErrorLogModal/ErrorLogModal'
import history from '../../../core/history';
const language = "en";

const VoyageTable = (props) => {
    const { VoyageNotFound, ServiceConnection, WaitForRequest, FirstLoad,
        VoyageData, VoyageRequestData, getVoyages, setVoyageId, setFirstLoad } = props;
    const VoyageNotFoundMessage = voyagePageLanguage[language].NoVoyageMessage
    const NoConnectionMessage = voyagePageLanguage[language].NoConnectionMessage
    const RenderComponents = VoyageTableErrorComponents(VoyageNotFound,
        !ServiceConnection, WaitForRequest, VoyageNotFoundMessage, NoConnectionMessage);

    const [ShowLogs,setShowLogs] = useState(false);
    const [VoyageErrorMessages,setVoyageErrorMessages] = useState([])
    const [selectedRow,setSelectedRow] = useState({})
    const onRowClick = (Row) => {
        if (Row.hasError) {
            setVoyageErrorMessages(Row.errorMessages)
            setSelectedRow(Row)
            setShowLogs(true)
            return;
        }
        setVoyageId(Row.voyageId);
        history.push("/declaration")
    }
    const onErrorLogsClose = () => {
        setShowLogs(false)
    }
    const controlButtonsClick = {
        ErrorStatus: () => {return} 
    }
    useEffect(() => {
        if (FirstLoad) {
            getVoyages(VoyageRequestData);
        }
    }, [])
    return (<>
        <VoyageErrorLogs Show={ShowLogs}  Title={'Voyage has error.'} onClose={onErrorLogsClose} 
        ErrorExplanation={selectedRow.voyageNumber + " 's errors below."} ErrorMessages={VoyageErrorMessages}/>
        <div className="voyageTableContent">
            <PerfectTable
                className={WaitForRequest ? "tableSpinning" : ""}
                DataArray={VoyageData}
                TableSchema={VoyageTableSchema}
                OnRowClick={onRowClick}
                RowRenderComps={RenderComponents}
                onControlBtnClick = {controlButtonsClick}
            />

        </div>
    </>

    );
}
const mapStateToProps = state => {
    return {
        WaitForRequest: state.voyage.WaitForRequest,
        ServiceConnection: state.voyage.ServiceConnection,
        VoyageNotFound: state.voyage.VoyageNotFound,
        VoyageData: state.voyage.VoyageData,
        VoyageRequestData: state.voyage.VoyageRequestData,
        FirstLoad: state.voyage.FirstLoad
    }
}
export default connect(mapStateToProps, { getVoyages, setVoyageId, setFirstLoad })(VoyageTable);