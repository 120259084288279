import React from 'react';
import './SuccessMessage.scss';

const successMessage = React.memo(props => {
    return (
        props.SuccessMessage &&
        <div className="successMessage">
            {props.SuccessMessage}
        </div>
    );
})
export default successMessage;