import React from 'react';
import './SpinButton.scss';
import { Spinner, Button } from '@dfds-ui/react-components';

const SpinButton = props => {

    return (
        <Button
            data-tip data-for={props.dataFor}
            className={props.waitForRequest ? "spinBtn " + props.className + " disabled" : "spinBtn " + props.className}
            variation="secondary"
            iconAlign="left"
            id={props.id}
            onClick={props.onClick}>
            {props.waitForRequest ? <Spinner id="butonSpin" instant={true} /> : props.ButtonLabel}
        </Button>
    )
}
export default SpinButton;