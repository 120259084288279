import httpClient from "../../core/httpclient";
import detailTypes from "../type/detail";
import ProjectConfig from '../../config/config'
import history from "../../core/history";
import declarationTypes from '../type/declaration'

export const getDetails = (VoyageId) => async dispatch => {
    //Check for voyage Id
    const LocalStorageVoyageId = localStorage.getItem(`${ProjectConfig.projectName}_VoyageId`)
    let LastVoyageId = VoyageId;
    if (VoyageId === "" && LocalStorageVoyageId === "") history.push('/')
    if (VoyageId === "") {
        dispatch({
            type: declarationTypes.SET_REQUEST_VOYAGE_ID,
            VoyageId: LocalStorageVoyageId
        })
        LastVoyageId = LocalStorageVoyageId
    }
    //-----------------
    const EndPoint = `${ProjectConfig.apiUrl}/voyage/getdisplaydetails`;
    dispatch({
        type: detailTypes.GETTING_DETAILS
    })
    httpClient.post(EndPoint, JSON.stringify(LastVoyageId))
        .then(response => {
            const Details = response.data.result;
            if (Object.keys(Details).length > 0) {
                dispatch({
                    type: detailTypes.GET_VOYAGE_DETAILS,
                    DetailsData: Details,
                });
            } else {
                dispatch({
                    type: detailTypes.NO_VOYAGE_DETAILS
                })
            }
        })
        .catch(error => {
            const errorResponse = error.response;
            const errorData = errorResponse ? errorResponse.data : null;
            const errors = errorData ? errorData.errors[0] : null;
            const errorCode = errors ? errors.code : "";
            const errorDesc = errors ? errors.description : "";
            dispatch({
                type: detailTypes.DETAILS_CONNECTION_ERROR,
                ErrorTitle: "Error",
                ErrorCode: errorCode,
                ErrorMessage: errorDesc
            });
        })
}
export const clearDetails = () => dispatch => {
    dispatch({
        type: detailTypes.CLEAR_VOYAGE_DETAILS
    })
}
export const clearDetailsError = () => dispatch => {
    dispatch({
        type: detailTypes.CLEAR_DETAILS_ERROR
    })
}
export const setSelectedBooking = (SelectedBooking) => dispatch => {
    dispatch({
        type: detailTypes.SET_SELECTED_BOOKING,
        SelectedBooking: SelectedBooking
    })
}
export const setFilterText = (FilterText) => dispatch => {
    dispatch({
        type: detailTypes.FILTER_DATA,
        FilterText: FilterText
    })
}