import React, { useState, useEffect } from 'react';
import { NavBar, NavBarItem } from '@dfds-ui/react-components'
import './Header.scss';
import { connect } from 'react-redux';
import { userOnline } from '../../store/action/userActions'
import NavBarItemDesigner from './NavBarItemDesigner'
import { NavBarConfig } from './NavBarConfig'
import { FaUserCircle } from "react-icons/fa";
import UserDrawer from './UserDrawer.js';
import { getUserStorage } from '../../core/projectStorage'
import { isTokenExist } from '../../core/authentication';

const Header = (props) => {
    const { pageName, userOnline, Username } = props;
    const [DrawerShow, setDrawer] = useState(false)
    useEffect(() => userOnline(), [])
    return (
        <>
            <NavBar className="PageNavbar">
                {/* <div className="MouseAreaOfUserSection"
                    onMouseLeave={() => {
                        if (DrawerShow)
                            setDrawer(false)
                    }} /> */}
                <NavBarItemDesigner pageName={pageName} config={NavBarConfig} />
                {
                    isTokenExist() &&
                    <div
                        onMouseLeave={() => {
                            if (DrawerShow)
                                setDrawer(false)
                        }}
                        className="userSection"
                        onClick={() => setDrawer(!DrawerShow)}>

                        <span id="userName">{Username}</span>

                        <FaUserCircle className="userButton" />

                        {DrawerShow && <UserDrawer />}
                    </div>
                }

            </NavBar>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName,
        Username: state.user.Username
    }
}
export default connect(mapStateToProps, { userOnline })(Header);
