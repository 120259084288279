import React from 'react'
export const CargoTableSchema = {
    ColumnDesign: [
        {
            dataId: "id",
            text: "Sequence Number of Cargo",
        },
        {
            dataId: "contents",
            text: "Contents Of Cargo",
        },
        {
            dataId: "quantity",
            text: "Quantity of Cargo",
        },
        {
            dataId: "grossWeight",
            text: "Gross Weight of Cargo",
        },
        {
            dataId: "mrnDeclarationType",
            text: "Declaration Type Code"
        },
        {
            dataId: "mrn",
            text : "Declaration Number"
        }
    ],
    DataDesign: [
        {
            dataId: "id",
        },
        {
            dataId: "contents",
        },
        {
            dataId: "quantity",
        },
        {
            dataId: "grossWeight",
        },
        {
            dataId: "mrnDeclarationType",
        },
        {
            dataId: "mrn"
        }
    ]
}
export const DummyCargoData = [
    {
        sequenceNumberCargo: 123456,
        contentsOfCargo: "Food",
        quantityOfCargo: 6,
        netWeight: 3000,
        grossWeight: 2700,
        declarationTypeCode: "no idea",
        declarationNumber: 32323232,
    },
    {
        sequenceNumberCargo: 123456,
        contentsOfCargo: "Food",
        quantityOfCargo: 6,
        netWeight: 3000,
        grossWeight: 2700,
        declarationTypeCode: "no idea",
        declarationNumber: 32323232,
    },
    {
        sequenceNumberCargo: 123456,
        contentsOfCargo: "Food",
        quantityOfCargo: 6,
        netWeight: 3000,
        grossWeight: 2700,
        declarationTypeCode: "no idea",
        declarationNumber: 32323232,
    },
    {
        sequenceNumberCargo: 123456,
        contentsOfCargo: "Food",
        quantityOfCargo: 6,
        netWeight: 3000,
        grossWeight: 2700,
        declarationTypeCode: "no idea",
        declarationNumber: 32323232,
    },
    {
        sequenceNumberCargo: 123456,
        contentsOfCargo: "Food",
        quantityOfCargo: 6,
        netWeight: 3000,
        grossWeight: 2700,
        declarationTypeCode: "no idea",
        declarationNumber: 32323232,
    }
]