import React, { useState, useEffect } from 'react';
import './PerfectTable.scss'
import { Information, Ship2, TrackingLogistics, Clear } from '@dfds-ui/icons';
import ReactTooltip from 'react-tooltip'
import { FaFilter } from "react-icons/fa";
import { searchString } from '../../../core/filter'
import { epochToShortDate, epochToDateTime } from '../../../core/epochToDateConverter'
import AllHides from '../SuccessMessage/FilterNotFound'
import Checkbox from '../CheckBox/CheckBox'
import BeautifulExcel from '../Excel/ExportExcelWithStyle'
import ExcelModal from '../Modals/YesNoModal/YesNoModal'



const PerfectTable = props => {
    const { className, OnRowClick, DataArray, TableSchema, RowRenderComps,
        SubTable, FilterOptions, OnCellClick, onControlBtnClick, ExcelOptions, bodyClassName } = props;
    const [tableRender, setTableRender] = useState(false);
    const renderTable = { tableRender: tableRender, setTableRender: setTableRender }
    const [filterPossible, setFilterPossible] = useState(true);
    const FilterCheck = { filterPossible: filterPossible, setFilterPossible: setFilterPossible }
    const [ExcelModal, setExcelModal] = useState(false)
    const ExcelModalState = { get: ExcelModal, set: setExcelModal };
    useEffect(() => {
        if (TableSchema.CheckTable) {
            TableSchema.Properties = DataArray && TableSchema.Properties === undefined ? Object.keys(DataArray).map(() => new Object()) : TableSchema.Properties;
            if (TableSchema.Properties !== undefined)
                TableSchema.Properties.map((Props, index) => Props.data = DataArray[index]);
        }
        return () => {
            TableSchema.RootCheck = false;
            TableSchema.Properties = undefined;

        }
    }, [DataArray])
    return (
        tableSelf({ className, OnRowClick, DataArray, TableSchema, RowRenderComps, SubTable, FilterOptions, OnCellClick, onControlBtnClick, ExcelOptions, bodyClassName }, renderTable, FilterCheck, ExcelModalState)
    );
}
const tableSelf = ({ className, OnRowClick, DataArray, TableSchema, RowRenderComps, SubTable, FilterOptions, OnCellClick, onControlBtnClick, ExcelOptions, bodyClassName },
    renderTable, FilterCheck, ExcelModalState, isSubTable = false) => {
    const renderColumns = (ColumnsArray) => {
        const columnRows = ColumnsArray.map((columnObj, key) => {
            return (
                <th key={key} id={(isSubTable) ? "pSubTh" : "pTh"}>
                    <span id="columnText">{columnObj.text}</span>
                    {
                        columnObj.filter &&
                        checkFilterIcon(columnObj, renderTable, FilterCheck)
                    }
                    {
                        columnObj.infoText &&
                        checkInfoIcon(columnObj.infoIcon, key)

                    }
                    {
                        columnObj.infoText &&
                        <ReactTooltip id={key + " tooltip"} type='info' effect="solid">
                            <span id="infoText">{columnObj.infoText}</span>
                        </ReactTooltip>
                    }
                </th>
            )
        })

        const checkBox = (TableSchema.CheckTable && DataArray) && DataArray.length > 0 && <th className="CheckTh"><Checkbox className="PerfectTableCheckbox" isChecked={TableSchema.RootCheck ? TableSchema.RootCheck : false} onChange={(event) => {
            if (event.target.checked) {
                TableSchema.Properties.map((Property) => Property.checked = true)
                TableSchema.RootCheck = true;
                renderTable.setTableRender(!renderTable.tableRender)

            }
            else {
                TableSchema.RootCheck = false;
                TableSchema.Properties.map((Property) => Property.checked = false)
                renderTable.setTableRender(!renderTable.tableRender)
            }
        }} /></th>
        // const finalResult = [checkBox, ...columnRows];
        const finalResult = [checkBox, ...columnRows];

        return (
            finalResult
        );
    }
    const objectToArray = data => {
        let arrayData = Object.keys(data).map(i => data[i]);
        return arrayData
    }
    const renderRows = (ColumnArray, DataDesingArray) => {
        if (RowRenderComps !== undefined && RowRenderComps !== null) {
            const result = RowRenderComps.map((CompsObj) => {
                if (CompsObj.show) {
                    return CompsObj.component;
                }
            });
            if (DataArray === null) {
                if (FilterCheck.filterPossible) {
                    ClearFilter(ColumnArray);
                    FilterCheck.setFilterPossible(false); //There is no data and filter

                }
                return result;
            }
            const rows = showRow(ColumnArray, DataDesingArray);
            if (result.some((element) => typeof (element) === "object")) {
                if (FilterCheck.filterPossible) {
                    ClearFilter(ColumnArray);
                    FilterCheck.setFilterPossible(false); //New Filter
                }
                let withDataResult;
                if (rows[0].props.BeCareful)
                    withDataResult = result;
                else
                    withDataResult = [...result, ...rows]
                return withDataResult;
            } else {
                if (!FilterCheck.filterPossible)
                    FilterCheck.setFilterPossible(true)
                return rows;
            }
        } else {
            const rows = showRow(ColumnArray, DataDesingArray);
            if (!FilterCheck.filterPossible && !isSubTable)
                FilterCheck.setFilterPossible(true)
            return rows;
        }
    }
    const hideOtherTables = (realDataArray, realObj) => {
        realDataArray.map((data) => {
            if (realObj !== data)
                data.tableShow = false;
        })
    }
    const SelectedRow = (RowArray, SelectedRow) => {
        RowArray.map((Row) => {
            if (SelectedRow === Row) {
                Row.__selected = true
            } else {
                Row.__selected = false
            }
        })
        renderTable.setTableRender(!renderTable.tableRender)
    }
    const onChangeCheckClick = (arrayPos) => {
        TableSchema.Properties[arrayPos].checked = (TableSchema.Properties[arrayPos].checked === undefined) ? true : !TableSchema.Properties[arrayPos].checked
        let allChecked = true;
        TableSchema.Properties.map((prop) => allChecked = allChecked && prop.checked)
        if (!allChecked) {
            TableSchema.RootCheck = false;
        } else {

            TableSchema.RootCheck = true;

        }
        renderTable.setTableRender(!renderTable.tableRender)
    }
    let allHiding = true;
    const showRow = (ColumnArray, DataDesingArray) => {
        let _realDataArray = []
        if (DataArray === null || DataArray === undefined) return;
        if (typeof (DataArray) === "object") _realDataArray = objectToArray(DataArray);
        else _realDataArray = DataArray;

        let calculateThings = []
        const Rows = _realDataArray.map((realObj, rkey) => {
            const hideRow = filterHide(ColumnArray, realObj, FilterOptions);
            allHiding = allHiding && hideRow
            let checkBox_Check = false
            if (TableSchema.CheckTable)
                checkBox_Check = (TableSchema.Properties !== undefined ? TableSchema.Properties[rkey].checked === undefined ? false : TableSchema.Properties[rkey].checked : false)
            return (
                <>
                    <tr className={(isSubTable ? ("pSubRow ") : ("pRow " + (realObj.__selected ? "SelectedRow" : ""))) + ((hideRow) ? " filteredRow" : "") + (realObj.tableShow ? "tableActive" : "") + (checkBox_Check ? " rowChecked" : "")} key={rkey}
                        onClick={() => {
                            if (OnRowClick !== undefined && typeof OnRowClick === "function") {
                                OnRowClick(realObj, rkey);
                            }
                            if (TableSchema.CheckTable) onChangeCheckClick(rkey)
                            TableSchema.Selectable && SelectedRow(_realDataArray, realObj)
                            if (SubTable !== undefined && SubTable !== null && SubTable !== "") {

                                hideOtherTables(_realDataArray, realObj)
                                ClearFilter(SubTable.TableSchema.ColumnDesign) // will change
                                realObj.tableShow = (realObj.tableShow === undefined) ? true : !realObj.tableShow;
                                renderTable.setTableRender(!renderTable.tableRender)
                            }

                        }}
                    >
                        {
                            TableSchema.CheckTable &&
                            <td className="CheckTd">
                                <Checkbox className="PerfectTableCheckbox"
                                    isChecked={checkBox_Check}
                                    onClick={(event) => onChangeCheckClick(rkey, event)} />
                            </td>
                        }
                        {
                            DataDesingArray.map((dataObj, dkey) => {
                                return (
                                    <td id={isSubTable ? "pSubTd" : "pTd"} key={dkey} onClick={(event) => {
                                        typeof OnCellClick === "function" && OnCellClick(realObj, dataObj.dataId)
                                    }}>
                                        {
                                        }
                                        {
                                            dataObj.calculate &&
                                            calculateStuff(realObj, dataObj, calculateThings)
                                        }
                                        {
                                            dataObj.icon &&
                                            checkColumnIcon(dataObj.icon)
                                        }
                                        {

                                            (dataObj.show || dataObj.show === undefined) &&
                                            checkVariable(dataObj.control) &&
                                            checkControl(dataObj, realObj, dataObj.control)

                                        }
                                        {

                                            checkVariable(dataObj.multipleControl) &&
                                            multipleControl(dataObj.controlIds, dataObj.controlResults.forAction, realObj, onControlBtnClick[dataObj.dataId], realObj)
                                        }
                                        {
                                            ((dataObj.show || dataObj.show === undefined))
                                            &&
                                            ((!checkVariable(dataObj.control) && !checkVariable(dataObj.multipleControl)) || (!checkVariable(dataObj.showData) ? true : dataObj.showData))
                                            &&
                                            <span key={dkey} id="cellText" className={dataObj.className !== undefined ? dataObj.className : ""}>{checkDataType(realObj[dataObj.dataId], dataObj.type)}</span>
                                        }

                                    </td>

                                )
                            })
                        }
                    </tr>
                    {(realObj.tableShow) &&
                        createSubTable(SubTable, realObj, renderTable, FilterCheck)
                    }
                </>
            );
        });
        if (allHiding) return [<AllHides Message={returnFilters(ColumnArray)} BeCareful={true} />]
        const calculatedRow = (Object.keys(calculateThings).length > 0) && showCalculatedRow(calculateThings, ColumnArray, isSubTable)
        const allRows = [...Rows, calculatedRow];
        return allRows;
    }
    const returnFilters = (ColumnArray) => {
        let filterText = ""
        let firstTimeNoComma = 0;
        ColumnArray.map((Column) => {
            if (Column.isFiltered) {
                filterText = filterText + ((firstTimeNoComma !== 0) ? " , " : " ") + Column.text + " : " + Column.filterText
                firstTimeNoComma = 1;
            }
        })
        return filterText + " Not Found"
    }
    const checkVariable = (variable, typeOf) => {
        return variable !== undefined && variable !== "" && variable !== null && (typeOf !== undefined ? (typeof variable === typeOf) : true)
    }
    const multipleControl = (ids, action, realObj, onControlBtnClick, RealObj) => {
        if (RealObj.DownloadStatus === undefined) RealObj.DownloadStatus = { Status: false }
        const realResults = ids.map((id) => realObj[id]);
        onControlBtnClick = typeof onControlBtnClick === "function" ? onControlBtnClick : () => null;
        return action(realResults, onControlBtnClick, realObj, renderTable, RealObj.DownloadStatus)
    }
    const showCalculatedRow = (calculateThings, ColumnArray, isSubTable) => {
        return (
            <tr className={(isSubTable ? ("pSubRow ") : "pRow ")}>
                {ColumnArray.map((column, key) => {
                    return <td key={key} id={isSubTable ? "pSubTd" : "pTd"}> {(calculateThings[column.dataId] !== null && calculateThings[column.dataId] !== undefined) ? <span id="finalResulText">
                        {"Total : " + calculateThings[column.dataId]}</span> : ""} </td>
                })}
            </tr>
        )
    }
    const calculateStuff = (RealData, DataDesign, Calculate) => {
        if (Calculate[DataDesign.dataId] === undefined || Calculate[DataDesign.dataId] === null || Calculate[DataDesign.dataId] === "") {
            Calculate[DataDesign.dataId] = RealData[DataDesign.dataId] === undefined ? "?" : parseFloat(RealData[DataDesign.dataId]);
        } else {
            Calculate[DataDesign.dataId] = parseFloat(Calculate[DataDesign.dataId]) + parseFloat(RealData[DataDesign.dataId], 2);
        }

    }
    const createSubTable = (SubTable, realObj, renderTable, FilterCheck) => {
        if (SubTable.DataConnection !== undefined) {
            SubTable.DataArray = realObj[SubTable.DataConnection]
        }
        if (SubTable.DataArray === null || SubTable.DataArray === undefined || SubTable.DataArray === "" || SubTable.DataArray.length < 1) return null;
        const pSubTable = tableSelf(SubTable, renderTable, FilterCheck, null, true)
        return pSubTable;
    }
    const checkControl = (dataModel, data, control) => {
        if (data[dataModel.dataId] === undefined) return;
        if (data[dataModel.controlId === undefined]) return;
        const ControlResult = dataModel.controlResult;
        switch (control.toUpperCase()) {
            case "ARRAY":
                return returnControl(ControlResult, data, dataModel, data[dataModel.controlId].length > 0)
            case "OBJECT":
                return returnControl(ControlResult, data, dataModel, Object.keys(data[dataModel.controlId]).length > 0);
            case "BOOL":
                return returnControl(ControlResult, data, dataModel, data[dataModel.controlId])
            default:
                return;
        }
    }
    const returnControl = (controlResult, data, dataModel, control) => {
        const iconLabel = control ? controlResult.forTrueLabel && <span className="controlIconText">{controlResult.forTrueLabel}</span> : controlResult.forFalseLabel && <span className="controlIconText">{controlResult.forFalseLabel}</span>;
        const actionResult = control ? typeof controlResult.forTrueAction === "function" && controlResult.forTrueAction() : typeof controlResult.forFalseAction === "function" && controlResult.forFalseAction()
        const icon = control ? controlResult.forTrueIcon && controlResult.forTrueIcon : controlResult.forFalseIcon && controlResult.forFalseIcon
        dataModel.className = (typeof controlResult.forTrueClass === "string" && typeof controlResult.forFalseClass === "string") && control ? controlResult.forTrueClass : controlResult.forFalseClass
        const result = [icon, iconLabel, actionResult];
        return result;
    }
    const checkDataType = (data, dataType) => {
        if (dataType === undefined) return data;
        switch (dataType.toUpperCase()) {
            case "DATE":
                return epochToShortDate(data);
            case "DATETIME":
                return epochToDateTime(data);
            case "DATEUTC":
                return epochToShortDate(data, true);
            case "DATETIMEUTC":
                return epochToDateTime(data, true)
        }
        return data;
    }
    const checkColumnIcon = (icon) => {
        if (typeof (icon) !== "string") return icon;
        switch (icon.toUpperCase()) {
            case "SHIP":
                return <Ship2 className="columnCellIcon" />
            case "TRACK":
                return <TrackingLogistics className="columnCellIcon" />
        }
        return <></>
    }
    const checkInfoIcon = (icon, key) => {
        if (typeof (icon) === "undefined")
            return <Information data-tip data-for={key + " tooltip"} className={"infoIcon"} />
        return icon;
    }
    const checkFilterIcon = (columnObj, renderTable, FilterCheck) => {
        if (columnObj.filterIcon === undefined)
            return (

                <>
                    <FaFilter className={"filterIcon " + ((FilterCheck !== undefined || FilterCheck !== null) ?
                        ((FilterCheck.filterPossible) ? (columnObj.isFiltered ? " active" : "") : "filterNotPossible") : (columnObj.isFiltered ? " active" : ""))} onClick={() => FilterCheck.filterPossible && checkFilterDiv(columnObj, renderTable)} />
                    {
                        columnObj.showFilter &&
                        <div className="filterMouseArea" onMouseLeave={() => checkFilterDiv(columnObj, renderTable)}>
                            <div className="filterPopup">
                                {
                                    (columnObj.inputActive) &&
                                    <label className="filterTextInputLabel">{(columnObj.filterLabelText !== "" &&
                                        columnObj.filterLabelText !== null && columnObj.filterLabelText !== undefined)
                                        ?
                                        columnObj.filterLabelText : columnObj.text}</label>
                                }
                                <input autoFocus={true} className={"filterTextInput " + ((columnObj.inputActive) ? "active" : "")}
                                    placeholder={columnObj.filterPlaceHolder}
                                    type="text"
                                    defaultValue={columnObj.filterText}
                                    onChange={(event) => OnFilterValueChange(event, columnObj, renderTable)}
                                    onKeyDown={(event) => (event.keyCode === 27 || event.keyCode === 13) && checkFilterDiv(columnObj, renderTable)}
                                    value={clearInputText(columnObj)}
                                />
                                {
                                    checkVariable(columnObj.filterText) &&
                                    <div className="clearFilterTextIconCont"><Clear className="clearFilterTextIcon" onClick={() => clearFilterText(columnObj, renderTable)} /></div>

                                }
                            </div>
                        </div>
                    }
                </>
            )
    }
    const clearFilterText = (data, renderTable) => {
        if (data.filterText !== "") {
            data.filterText = "";
            data.inputActive = false;
            data.isFiltered = false;
            data.clearTextInput = true;
            renderTable.setTableRender(!renderTable.tableRender)

        }
    }
    const clearInputText = (data) => {
        if (data.clearTextInput) {
            data.clearTextInput = false;
            return "";
        }
    }
    const checkFilterDiv = (data, renderTable) => {
        if (data.filterText === "") data.inputActive = false;
        if (data.showFilter === undefined) {
            data.showFilter = true;
            renderTable.setTableRender(!renderTable.tableRender)
            return;
        }
        data.showFilter = !data.showFilter;
        renderTable.setTableRender(!renderTable.tableRender)
    }
    const OnFilterValueChange = (event, data, renderTable) => {
        data.filterText = event.target.value;
        if (event.target.value !== "") {
            data.isFiltered = true;
            if (!data.inputActive || data.inputActive === undefined) {

                data.inputActive = true;
                renderTable.setTableRender(!renderTable.tableRender)
                return;
            }
            renderTable.setTableRender(!renderTable.tableRender)
            return;
        }
        data.inputActive = false;
        data.isFiltered = false;
        renderTable.setTableRender(!renderTable.tableRender)
    }
    const filterHide = (ColumnArray, Data, FilterOptions) => {
        let result = false
        ColumnArray.forEach((column, index) => {
            if (column.isFiltered) {
                const value = Data[column.dataId];
                if (column.filterCaseSensitive === undefined)
                    column.filterCaseSensitive = false;
                const filterFound = (value !== null) ? searchString(column.filterText, value, column.filterCaseSensitive) : false;
                result = (result || !filterFound); // because if result false => show row
            }
        })

        if (Array.isArray(FilterOptions)) {
            if (FilterOptions.length > 0) {
                FilterOptions.map((Filter) => {
                    const value = Data[Filter.dataId];
                    if (value === undefined) return;
                    switch (Filter.queType.toUpperCase()) {
                        case "CHECKLENGTH":
                            if (Filter.desire === null || Filter.desire === undefined || Filter.desire === Filter.default)
                                return;
                            const FilterDesire = (Filter.desire === "false" || !Filter.desire) ? false : true;
                            if (FilterDesire)
                                result = (result || (value.length < 1))
                            else
                                result = (result || (value.length > 0))
                            return;
                    }
                })
            }
        }
        if (result === true) {
            Data.tableShow = false;

        } else {
            VisibleData.push(Data);
        }
        return result;
    }
    let VisibleData = [];
    const ClearFilter = (ColumnArray) => {
        ColumnArray.map((column) => {
            if (column.isFiltered) {
                column.filterText = "";
                column.inputActive = false;
                column.isFiltered = false;
            }
        })
    }
    const realExcelButton = ExcelOptions && <BeautifulExcel
        ExcelSchema={ExcelOptions.ExcelShema}
        DataArray={VisibleData}
        SheetName={ExcelOptions.SheetName}
        FileName={ExcelOptions.FileName}
        ButtonComp={ExcelOptions.ModalButton}
        ExtraData={ExcelOptions.ExtraData}
    />


    return (
        <>
            <table
                className={(isSubTable) ? ("PerfectSubTable " + (typeof className === "string" ? className : "")) : ("PerfectTable " + (typeof className === "string" ? className : ""))}>
                <thead className={(isSubTable) ? "pSubthead" : "pthead"}>
                    <tr className={isSubTable ? "pSubColumn" : "pColumn"}>
                        {renderColumns(TableSchema.ColumnDesign, renderTable, FilterCheck, isSubTable)}
                    </tr>
                </thead>
                <tbody
                    className={(isSubTable) ? "pSubtbody" : ("ptbody" + " " + (bodyClassName ? bodyClassName : "") + ((DataArray === null) ? " noResult" : ((Object.keys(DataArray).length < 1) ? " noResult" : "")))}>
                    {renderRows(TableSchema.ColumnDesign, TableSchema.DataDesign)}
                </tbody>
            </table>
            {
                (realExcelButton) &&
                <ExcelModal OpenModal={ExcelOptions.ShowModal === undefined ? false : ExcelOptions.ShowModal}
                    Title="Export Excel "
                    Message={"Do you want to export " + ExcelOptions.FileName + " ?"}
                    OnClose={ExcelOptions.onExcelClose} yesButtonComp={realExcelButton} />
            }
            {
                realExcelButton && DataArray && (VisibleData.length > 0) && !allHiding &&
                ExcelOptions.Button
            }
        </>
    )
}

export default React.memo(PerfectTable);