import React, { useEffect } from 'react';
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { DeclarationTableSchema, DummyDeclarationData } from './DeclarationTableSchema'
import DeclarationHeader from './DeclarationTableHeader'
import './DeclarationTable.scss'
import { connect } from 'react-redux';
import { modalShow } from '../../../store/action/modalActions'
import DeclarationTableErrorComponents from '../../CommonUI/TableErrorComponents/TableErrorComponents'
import { getDeclarations, setError,UpdateRefreshTime } from '../../../store/action/declarationAction'
import DownloadFile from '../../../core/downloadFile'
import { epochToDateTimeNoFormat, epochToDateTime } from '../../../core/epochToDateConverter'
import { declarationPageLanguage } from '../../../config/language'
import ProjectConfig from '../../../config/config';
const language = "en";


const DeclarationTable = (props) => {
    const { DeclarationNotFound, ServiceConnection, WaitForRequest, DeclarationData,
        getDeclarations, modalShow, VoyageId, setError,UpdateRefreshTime } = props;


    const AfterDownload = () => {
        getDeclarations(VoyageId)
    }

    const onDownloadClick = (selectedRow, DownloadStatus, RenderTable) => {
        const DownloadInformation = getDownloadInfoFromType(selectedRow)
        const DownloadUrl = DownloadInformation.ApiUrl
        const Filename = DownloadInformation.Filename
        DownloadStatus.Status = true;
        RenderTable.setTableRender(!RenderTable.tableRender)
        DownloadFile(DownloadUrl, JSON.stringify(VoyageId), Filename, AfterDownload, 1000).catch((error) => {
            setError("Download Error", error.ErrorCode, error.ErrorDesc)
        }).finally(() => {
            UpdateRefreshTime()
        })
    }

    const onHistoryClick = (selectedRow) => {
        modalShow({
            Modal: "HISTORY_MODAL",
            Row: selectedRow

        })
    }

    const onManualClick = (selectedRow) => {
        modalShow({
            Modal: "MANUAL_MODAL",
            Row: selectedRow

        })
    }

    const onSignClick = (selectedRow) => {
        modalShow({
            Modal: "SIGN_MODAL",
            Row: selectedRow

        })
    }

    const onPdfClick = (selectedRow, DownloadStatus, RenderTable) => {
        const PDFUrl = `${ProjectConfig.apiUrl}/summarydeclaration/downloaddeparturesummarypdffile`
        const RouteId = selectedRow.routeId;
        const PortCode = selectedRow.portCode;
        const PDFName = `CIKONC-${PortCode}-${RouteId}.pdf`
        const DeclarationId = selectedRow.declarationId
        DownloadStatus.Status = true;
        RenderTable.setTableRender(!RenderTable.tableRender)
        DownloadFile(PDFUrl, JSON.stringify(DeclarationId), PDFName, AfterDownload, 1000).catch((error) => {
            setError("Download Error", error.ErrorCode, error.ErrorDesc)
        }).finally(() => {
            UpdateRefreshTime()
        })
    }

    const onRowClick = (selectedRow) => {
    }

    const getDownloadInfoFromType = (Row) => {
        const RouteId = Row.routeId;
        const PortCode = Row.portCode;
        const UpdateDate = epochToDateTimeNoFormat(Row.updateDate)
        const RowType = Row.type
        switch (RowType) {
            case "EMANIF": // URL CHANGING BECAUSE TYPE THIS IS WHY SWITCH CASE USED HERE
                return {
                    ApiUrl: `${ProjectConfig.apiUrl}/summarydeclaration/downloademaniffile`,
                    Filename: `EMANIF-${PortCode}-${RouteId}-${UpdateDate}.xlsx`
                }
            case "CIKONC":
                return {
                    ApiUrl: `${ProjectConfig.apiUrl}/summarydeclaration/downloaddeparturesummarydeclarationfile`,
                    Filename: `CIKONC-${PortCode}-${RouteId}-${UpdateDate}.xml`
                }
            case "VARONC":
                return {
                    ApiUrl: `${ProjectConfig.apiUrl}/summarydeclaration/downloadarrivalsummarydeclarationfiles`,
                    Filename: `VARONC-${PortCode}-${RouteId}-${UpdateDate}.zip`
                }
        }
    }

    const DeclarationNotFoundMessage = declarationPageLanguage[language].NoDeclarationMessage

    const NoConnectionMessage = declarationPageLanguage[language].NoConnectionMessage

    const RenderComponents = DeclarationTableErrorComponents(DeclarationNotFound,
        !ServiceConnection, WaitForRequest, DeclarationNotFoundMessage, NoConnectionMessage);

    const controlButtonsClick = {
        showDownloadButton: onDownloadClick,
        History: onHistoryClick,
        showManuelMatchButton: onManualClick,
        showSignButton: onSignClick,
        showPDFButton: onPdfClick,
    }
    return (
        <>
            <DeclarationHeader />
            <PerfectTable
                bodyClassName={(WaitForRequest ? "tableSpinning" : "")}
                DataArray={DeclarationData && DeclarationData.declarationList}
                TableSchema={DeclarationTableSchema}
                OnRowClick={onRowClick}
                RowRenderComps={RenderComponents}
                onControlBtnClick={controlButtonsClick}
            />
        </>
    );
}
const mapStateToProps = state => {
    return {
        DeclarationData: state.declaration.DeclarationData,
        WaitForRequest: state.declaration.WaitForRequest,
        DeclarationNotFound: state.declaration.DeclarationNotFound,
        ServiceConnection: state.declaration.ServiceConnection,
        VoyageId: state.declaration.VoyageId,
    }
}
export default connect(mapStateToProps, { modalShow, getDeclarations, setError, UpdateRefreshTime })(DeclarationTable);
