import React from 'react'
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";

export const VoyageTableSchema = {
    ColumnDesign: [
        {
            dataId: "voyageNumber",
            text: "Voyage Number",
            filter: "text",
            filterLabelText: "Voyage Number",
            filterPlaceHolder: "Filter for Voyage Number",
        },
        {
            dataId: "vesselName",
            text: "Vessel Name",
            filter: "text",
            filterLabelText: "Vessel Name",
            filterPlaceHolder: "Filter for Vessel Name",
            filterCaseSensitive: false
        },
        {
            dataId: "loadingPort",
            text: "POL",
            infoText: "Port of Loading",
        },
        {
            dataId: "unLoadingPort",
            text: "POD",
            infoText: "Port of Departure",
        },
        {
            dataId: "scheduleDateTime",
            text: "Departure Date"
        },
        {
            dataId: "expectedArrivalDateTime",
            text: "E.T.A.",
            infoText: "Estimated Time of Arrival",
        },
        {
            dataId: "direction",
            text: "Direction",
        },
        {
            dataId: "emanifStatus",
            text: "E-Manif",
        },

        {
            dataId: "declarationStatus",
            text: "Summary Declaration",

        },
        {
            dataId: "creationDate",
            text: "Last Update Time",
            // infoText: "Last Updated Time"
        },
        {
            dataId: "ErrorStatus",
            text: "",
        },
    ],
    DataDesign: [
        {
            dataId: "voyageNumber",
            // icon : "ship"
        },
        {
            dataId: "vesselName",
        },
        {
            dataId: "loadingPort",
        },
        {
            dataId: "unLoadingPort",
        },
        {
            dataId: "scheduleDateTime",
            type: "datetime",
        },
        {
            dataId: "expectedArrivalDateTime",
            type: "datetime",
        },
        {
            dataId: "direction",

        },
        {
            dataId: "emanifStatus",

        },
        {
            dataId: "declarationStatus",

        },
        {
            dataId: "creationDate",
            type: "datetimeutc"
        },
        {
            dataId: "ErrorStatus",
            multipleControl: true,
            controlIds: ["hasError"],
            controlResults: {
                forAction: (controls, onClick, row) => ErrorCheck(controls, onClick, row)
            },
            showData: false,
        },
    ]
}
const ErrorCheck = (controls, onClick, row) => {
    // debugger;
    if (!controls[0]) {
        return <FaRegCheckCircle onClick= {()=>onClick()} className="controlIconTrue" />
    } else {
        return <FaRegTimesCircle onClick= {()=>onClick()} className="controlIconFalse" />
    }
}