import React from 'react'
import JustCanvas from '../JustCanvas/JustCanvas'
import { Spinner } from '@dfds-ui/react-components';
import PropTypes from 'prop-types';

import './JustWait.scss'

const JustWait = (props) => {
    const { Text, CanvasWidth } = props;
    return (
        <JustCanvas CanvasWidth={CanvasWidth}>
            <div className="JustCanvas-WaitForRequest">
                <Spinner className="WaitForRequest-Icon" instant={true} />
                <span id="WaitForRequest-Text">{Text}</span>
            </div>
        </JustCanvas>
    )
}
JustWait.propTypes = {
    Text: PropTypes.string,
    CanvasWidth: PropTypes.number
}
JustWait.defaultProps = {
    Text: "",
    CanvasWidth: 1260
}
export default JustWait;