const RowLocker = (rowLock, rowData) => {
    if (rowLock === null) return false
    if (typeof rowLock !== "object") return false
    let controller = false;
    rowLock.map((rowCondition) => {
        if (rowData[rowCondition.columnId] === rowCondition.Equal) {
            controller = true
            return
        }
    })
    return controller
}
export default RowLocker
// {
//     columnId: "isLocked",
//     Equal: true
// },