import React, { useState, useEffect } from 'react';
import { Modal } from '@dfds-ui/modal';
import { connect } from 'react-redux';
import { modalHide } from '../../../../store/action/modalActions'
import UserInput from '../../../CommonUI/UserInput/UserInput';
import './ManualModal.scss'
import { Button, Spinner } from '@dfds-ui/react-components';
import DatePicker from 'react-datepicker'
import { AiOutlineNumber } from "react-icons/ai";
import { MdDateRange } from 'react-icons/md';
import httpClient from '../../../../core/httpclient';
import ProjectConfig from '../../../../config/config';
import { getDeclarations, setVoyageId } from '../../../../store/action/declarationAction'
import history from '../../../../core/history';
import XMSErrorHandler from '../../../../core/xmsErrorHandler';

const ManualModal = (props) => {
    const { ManualModal, modalHide, Row, VoyageId, setVoyageId, getDeclarations } = props;
    const [RegistrationDate, setRegistrationDate] = useState(new Date())
    const [RegistrationNumber, setRegistrationNumber] = useState("")
    const [WaitForRequest, setWaitForRequest] = useState(false);
    const [ServiceConnection, setServiceConnection] = useState(true);
    const [InputValidation, setInputValidation] = useState(false)
    const [ManualUpdated, setManualUpdated] = useState(false)
    const [ServiceError, setError] = useState({ 
        ErrorCode: -1,
        ErrorMessage: "",
        ErrorTitle: ""
    })
    const [ButtonText, setButtonText] = useState("Save")
    const clearError = () => {
        setError({
            ErrorCode: -1,
            ErrorMessage: "",
            ErrorTitle: "",
        })
    }
    useEffect(() => {
        if (RegistrationDate !== null && RegistrationDate !== "" &&
            RegistrationNumber !== "" && RegistrationNumber !== null) {
            if (RegistrationNumber.trim() === "") {
                setInputValidation(false)
                return;
            }
            setInputValidation(true)
            setButtonText("Save")
        } else {
            setInputValidation(false)
        }
    }, [RegistrationNumber, RegistrationDate])
    const onClose = () => {
        modalHide({ Modal: "MANUAL_MODAL" });
        setInputValidation(false)
        if (ManualUpdated) getDeclarationsAfterClose()
    }
    const Update = () => {
        if (!InputValidation) return;
        if (WaitForRequest) return;
        const ShortDate = DateWithoutSeconds(RegistrationDate)
        const Epoch = ShortDate.valueOf()
        const ShortEpoch = Epoch / 1000;
        setWaitForRequest(true)
        setButtonText("")
        const DeclarationId = Row.declarationId;
        const apiUrl = `${ProjectConfig.apiUrl}/summarydeclaration/manualupdatedeclaration`
        const Request = {
            declarationId: DeclarationId,
            registrationNumber: RegistrationNumber,
            registrationDate: ShortEpoch,
        }
        httpClient.post(apiUrl, Request)
            .then(response => {
                setButtonText("Updated")
                clearError()
                setInputValidation(false)
                setManualUpdated(true)
                setTimeout(() => onClose(), 500)
            })
            .catch(error => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                setServiceConnection(false)
                clearError()
                setButtonText("Save")
            })
            .finally(() => {
                setWaitForRequest(false)
            })
    }
    const DateWithoutSeconds = (myDate) => {
        const Year = myDate.getFullYear()
        const Month = myDate.getMonth();
        const Day = myDate.getDate();
        const Hours = myDate.getHours()
        const Minutes = myDate.getMinutes()
        return new Date(Year, Month, Day, Hours, Minutes)
    }
    const getDeclarationsAfterClose = () => {
        const LocalStorageVoyageId = localStorage.getItem(`${ProjectConfig.projectName}_VoyageId`)
        if (VoyageId === "" && LocalStorageVoyageId === "") history.push('/')
        if (VoyageId === "") {
            getDeclarations(LocalStorageVoyageId)
            setVoyageId(LocalStorageVoyageId)
        } else {
            getDeclarations(VoyageId)
        }
    }
    return (
        <Modal className="manualUpdateModal" heading={"Manual Update"} isOpen={ManualModal} onRequestClose={() => { onClose() }}>
            <div className="ManualUpdate">
                <p id="prop1">Registration Number</p>
                <UserInput
                    isValid={true}
                    imgComp={<AiOutlineNumber />}
                    holder={"Registration Number"}
                    type='text'
                    onChange={(event) => setRegistrationNumber(event.target.value)}
                />
                <p id="prop2">Registration Date</p>
                <div className="DateWrapper">
                    <div className="IconHolder">
                        <MdDateRange className="DateTimeIcon" />
                    </div>
                    <DatePicker
                        className="rDate"
                        showPopperArrow={true}
                        selected={RegistrationDate}
                        onChange={date => setRegistrationDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                    />
                </div>
                <div className="messageBox">
                    <span id="message">{ServiceError.ErrorMessage}</span>
                </div>
                <Button
                    onClick={() => { Update() }}
                    className="saveButton"
                    id={(InputValidation && !WaitForRequest) ? "Clickable" : "NotClickable"}
                    icon={WaitForRequest && <Spinner className="posCenter" />}
                >
                    {ButtonText}
                </Button>
            </div>
        </Modal >
    )
}
const mapStateToProps = state => {
    return {
        ManualModal: state.modal.ManualModal,
        Row: state.modal.Row,
        VoyageId: state.declaration.VoyageId,
    }
}
export default connect(mapStateToProps, { modalHide, getDeclarations, setVoyageId })(ManualModal);