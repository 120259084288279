const detailsType = Object.freeze({
    GET_VOYAGE_DETAILS: Symbol('GET_VOYAGE_DETAILS'),
    CLEAR_VOYAGE_DETAILS: Symbol('CLEAR_VOYAGE_DETAILS'),
    DETAILS_CONNECTION_ERROR: Symbol('DETAILS_CONNECTION_ERROR'),
    NO_VOYAGE_DETAILS: Symbol('NO_VOYAGE_DETAILS'),
    GETTING_DETAILS: Symbol('GETTING_DETAILS'),
    CLEAR_DETAILS_ERROR: Symbol('CLEAR_DETAILS_ERROR'),
    SET_SELECTED_BOOKING: Symbol('SET_SELECTED_BOOKING'),
    FILTER_DATA: Symbol('FILTER_DATA')
});

export default detailsType;