export const searchString = (filterText, Text, CaseSensitive) => {
    let _text = Text;
    let _filterText = filterText;
    if (!CaseSensitive) {
        _filterText = _filterText.toUpperCase();
        _text = _text.toUpperCase();
    }
    return _text.includes(_filterText);
}
export const genericFilter = () => {
    
}