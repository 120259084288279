import React from 'react'
import JustCanvas from '../JustCanvas/JustCanvas'
import { FaExclamationCircle } from "react-icons/fa";
import PropTypes from 'prop-types';
import './JustError.scss'
const JustError = (props) => {
    const { Text, CanvasWidth, FontSize } = props;
    return (
        <JustCanvas CanvasWidth={CanvasWidth}>
            <div className="JustCanvas-NetworkError">
                <FaExclamationCircle className="NetworkError-Icon" />
                <span style={{ fontSize: FontSize }} id="NetworkError-Text">{Text}</span>
            </div>
        </JustCanvas>
    )
}
JustError.propTypes = {
    Text: PropTypes.string,
    CanvasWidth: PropTypes.number,
    FontSize: PropTypes.string,
}
JustError.defaultProps = {
    Text: "No Connection",
    CanvasWidth: 1260,
    FontSize: "24px",
}
export default JustError;