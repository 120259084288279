import React from 'react'
import './JustSorter.scss'
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";
import { getDataFromParent } from '../../ObjectTree';

export default class JustSorter extends React.Component {
    constructor(props) {
        super(props)
        const cloneArray = JSON.parse(JSON.stringify(props.DataArray))
        this.state = { SortState: "NONE", CloneDataArray: cloneArray /**for return first */ }
    }
    shouldComponentUpdate() {
        return true
    }

    sortArray = () => {
        const sortState = this.state.SortState
        const columnId = this.props.ColumnId
        const DataArray = this.props.DataArray
        const bindOutside = this.props.AfterSort
        const parentIds = this.props.ParentIds
        const deepCloneArray = JSON.parse(JSON.stringify(DataArray))
        // const DataHistory = this.props.DataHistory
        if (bindOutside && typeof bindOutside === "function" && DataArray && Array.isArray(DataArray)) {
            switch (sortState) {
                case "NONE":
                    this.setState({ SortState: "SORT-DOWN" })

                    bindOutside(deepCloneArray.sort((a, b) => {
                        if (parentIds && parentIds.length > 0) {
                            const firstRow = getDataFromParent(parentIds, a, columnId)
                            const secondRow = getDataFromParent(parentIds, b, columnId)
                            return firstRow - secondRow
                        } else {
                            return a[columnId] - b[columnId]
                        }
                    }))
                    break
                case "SORT-DOWN":
                    this.setState({ SortState: "SORT-UP" })
                    bindOutside(deepCloneArray.sort((a, b) => {
                        if (parentIds && parentIds.length > 0) {
                            const firstRow = getDataFromParent(parentIds, a, columnId)
                            const secondRow = getDataFromParent(parentIds, b, columnId)
                            return secondRow - firstRow
                        } else {
                            return b[columnId] - a[columnId]
                        }
                    }))
                    break;
                case "SORT-UP":
                    this.setState({ SortState: "NONE" })
                    bindOutside(this.state.CloneDataArray)
                    break;
                default:
                    break
            }
        }

    }
    render() {
        const sortState = this.state.SortState
        return (
            <div ref={this.props.sorterRef} onClick={() => this.sortArray()} className="JustSorter-Container">
                <GoTriangleUp viewBox="0 -2 12 16" className={"JustSorter-TriangleUp" + (sortState === "SORT-UP" ? " JustSorter-Sorted" : "")} />
                <GoTriangleDown viewBox="0 4 12 16" className={"JustSorter-TriangleDown" + (sortState === "SORT-DOWN" ? " JustSorter-Sorted" : "")} />
            </div>
        )
    }
}