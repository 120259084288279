import React from 'react';
import login from '../login';
import voyage from '../voyage';
import declaration from '../declaration';
import details from '../details'
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../core/history'
import PrivateRoute from './PrivateRoute.js';
import HomePage from '../../component/HomePage/HomePage'
import NoAuth from '../../component/NoAuth/NoAuth';
import resetPassword from '../resetpassword';
import adminpanel from '../adminpanel'


const SDRouter = () => {
    const authRoles = "internaluser,Admin";
    return (
        <>
            <Router history={history}>
                <Switch>
                    {/* <PrivateRoute exact path='/voyage' auth={authRoles} /> */}
                    <PrivateRoute path='/voyage' auth={authRoles} component={voyage} />
                    <PrivateRoute path='/declaration' auth={authRoles} component={declaration} />
                    <PrivateRoute path='/details' auth={authRoles} component={details} />
                    <PrivateRoute exact path='/adminpanel' component={adminpanel} auth={"Admin"} />

                    <PrivateRoute exact path='/noAuthorization' component={NoAuth} />
                    <Route path='/password-reset' component={resetPassword} />
                    <Route path='/login' component={login} />
                    <Route path='/' component={HomePage} />
                </Switch>
            </Router>
        </>
    )
}

export default SDRouter;