import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './Breadcrumb.css'
const Breadcrumb_Maker = (props) => {
    const { Seperator, className, Options } = props;
    return (
        <Breadcrumb label={Seperator} className={"Breadcrumb-Bootstrap " + (className ? className : "")}>
            {
                Options.map((Option,key) => {
                    if (Option.Active) {
                        return <Breadcrumb.Item key={key} active>{Option.Pagename}</Breadcrumb.Item>
                    } else {
                        return <Breadcrumb.Item key={key} onClick={() => Option.onClick()}>{Option.Pagename}</Breadcrumb.Item>
                    }
                })
            }
        </Breadcrumb>
    )
}
export default Breadcrumb_Maker;