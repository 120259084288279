import React from 'react'
import './JustCanvas.scss'
const JustCanvas = (props) => {
    return (
        <tbody className="JustTable-Table-Rows">
            <tr className="JustTable-TableRow">
                <th>
                    <div style={{ width: (props.CanvasWidth + "px") }} className="JustTable-JustCanvas">
                        {props.children}
                    </div>
                </th>
            </tr>
        </tbody>

    )
}
export default JustCanvas;