import React, { useEffect, useState, useRef } from 'react';
import './Details.scss';
import BookingTable from './BookingTable/BookingTable'
import CargoTable from './CargoTable/CargoTable'
import { connect } from 'react-redux';
import ProjectConfig from '../../config/config';
import Breadcrumb from '../CommonUI/Breadcrumb/Breadcrumb';
import { Refresh } from '@dfds-ui/icons'
import { getDetails, clearDetails, setFilterText } from '../../store/action/detailActions'

import history from '../../core/history';
import { Button, Input } from '@dfds-ui/react-components';
import TagBox from '../../component/CommonUI/TagBox/TagBox'
// import {DetailsLanguage} from '../../config/language/language'
const language = "en"

const Details = (props) => {
    const { DetailsData, VoyageId, getDetails, clearDetails, FilterText, setFilterText } = props;
    const InputRef = useRef()
    const [RefreshTime, setRefreshTime] = useState(`
    ${("0" + (new Date().getHours()).toString()).slice(-2)}.${("0" + (new Date().getMinutes()).toString()).slice(-2)}`)

    const BreadcrumbOptions = [
        {
            Pagename: "Voyage",
            onClick: () => { history.push("/voyage") }
        },
        {
            Pagename: "Declaration",
            onClick: () => { history.push("/declaration") }
        },
        {
            Pagename: "Details",
            Active: true
        }
    ]
    useEffect(() => {
        getDetails(VoyageId)
    }, [])
    useEffect(() => {
        return function cleanSelectedBooking() {
            clearDetails()
        }
    }, [])
    const clearFilterText = () => {
        setFilterText(null)
        InputRef.current.value = ""
    }
    const onInputKeyDown = (e) => {
        if (e.key === "Enter") {
            setFilterText(InputRef.current.value)
        }
    }
    return (
        <>
            <Breadcrumb Options={BreadcrumbOptions} Seperator={'>'} className={""} />

            <div className="RefreshButtonContainer">
                <Button id="refreshDateButton"
                    onClick={() => {
                        getDetails(VoyageId)
                        setRefreshTime(`
                ${("0" + (new Date().getHours()).toString()).slice(-2)}.${("0" + (new Date().getMinutes()).toString()).slice(-2)}`
                        )
                    }}>
                    Refresh
                </Button>
                <span id="refreshDateText">{`Last refresh time : ${RefreshTime}`}</span>

            </div>
            <div className="DetailsContainer">
                <div className="pageHeader">
                    <h4 id="VesselName">
                        {localStorage.getItem(`${ProjectConfig.projectName}_Vesselname`)}
                    </h4>
                    <h4 id="Custom">
                        {localStorage.getItem(`${ProjectConfig.projectName}_Portname`) + " Custom"}
                    </h4>
                </div>
                {
                    DetailsData &&
                    <div className="Declaration-Number-Filter-Container">
                        <Input ref={InputRef}
                            size={"small"}
                            className="Declaration-Number-Filter-Input"
                            type="text"
                            onKeyDown={(e) => onInputKeyDown(e)}
                            name="Declaration-Number-Filter-Input"
                            placeholder="Filter Declaration Number" />
                        <Button
                            onClick={() => {
                                setFilterText(InputRef.current.value)
                            }}
                            className="Declaration-Number-Filter-Button">
                            Filter
                    </Button>
                        <TagBox FilterText={FilterText} onDeleteClick={clearFilterText} />
                    </div>
                }
                <div className="bookingTableContainer">
                    <h5 id="bTableHeader">Booking Details</h5>
                    <BookingTable />

                </div>
                <div className="CargoTableContainer">
                    <h5 id="cTableHeader">Cargo Details</h5>
                    <CargoTable />
                </div>
            </div>
        </>
    );
}
const mapStateToProps = state => {
    return {
        ErrorMessage: state.detail.Error.ErrorMessage,
        ErrorTitle: state.detail.Error.ErrorTitle,
        ErrorCode: state.detail.Error.ErrorCode,
        DetailsData: state.detail.DetailsData,
        FilterText: state.detail.FilterText,
        VoyageId: state.declaration.VoyageId,
        DeclarationData: state.declaration.DeclarationData,
    }
}
export default connect(mapStateToProps, { getDetails, clearDetails, setFilterText })(Details);