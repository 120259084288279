import React from 'react';
import PerfectTable from '../../CommonUI/Table/PerfectTable'
import { CargoTableSchema, DummyCargoData } from './CargoTableSchema'
import { connect } from 'react-redux';
import { setSelectedBooking } from '../../../store/action/detailActions'
import BookingTableErrorComponents from '../../CommonUI/TableErrorComponents/TableErrorComponents'
import './CargoTable.scss'

const CargoTable = (props) => {
    const { SelectedBooking, WaitForRequest,DetailNotFound,ServiceConnection } = props;
    const RenderComponents = BookingTableErrorComponents(DetailNotFound,
        !ServiceConnection, WaitForRequest, "Details Not Found", "Connection Error");
    const onRowClick = (Row) => {}
    const CargoDetails = SelectedBooking && SelectedBooking.cargoDetails
    return (
        <PerfectTable
            bodyClassName={(WaitForRequest ? "tableSpinning" : "")}

            className={"CargoTable StaticTable "}
            DataArray={CargoDetails}
            TableSchema={CargoTableSchema}
            OnRowClick={onRowClick}
            RowRenderComps = {RenderComponents}
        />
    );
}
const mapStateToProps = state => {
    return {
        SelectedBooking: state.detail.SelectedBooking,
        WaitForRequest: state.detail.WaitForRequest,
        DetailNotFound : state.detail.DetailNotFound,
        ServiceConnection : state.detail.ServiceConnection
    }
}
export default connect(mapStateToProps, { setSelectedBooking })(CargoTable);
