const signTypes = Object.freeze({
    ON_SIGNING: Symbol('ON_SIGNING'),
    SIGNED: Symbol('SIGNED'),
    SIGN_ERROR: Symbol('SIGN_ERROR'),
    SIGN_CONNECTION_ERROR: Symbol('SIGN_CONNECTION_ERROR'),
    SET_PINCODE: Symbol('SET_PINCODE'),
    CLEAR_SIGN_ERROR: Symbol('CLEAR_SIGN_ERROR'),
    CLEAR_SIGN: Symbol('CLEAR_SIGN'),
    SET_SIGNATURE_DETAILS: Symbol('SET_SIGNATURE_DETAILS'),
    CLEAR_SIGNATURE_DETAILS: Symbol('CLEAR_SIGNATURE_DETAILS'),
    SHOW_ON_SIGN_MODAL: Symbol('SHOW_ON_SIGN_MODAL'),
    GET_USERS: Symbol('GET_USERS'),
    GETTING_USERS : Symbol('GETTING_USERS'),
    SET_SIGN_USER : Symbol('SET_SIGN_USER'),
});

export default signTypes;