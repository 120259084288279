import React, { useState, useEffect } from 'react'
import './HeaderCell.scss'
import PropTypes from 'prop-types';
import LeftCalc from '../../../Helper/LeftCalculator'
const HeaderCell = (props) => {
    const { ColumnDesign, ColumnDesigns, designIndex, onHeaderCellClick } = props;
    const [columnnStyle, setColumnStyle] = useState(null)
    const dataId = ColumnDesign.dataId;
    const designLock = ColumnDesign.Options ? (ColumnDesign.Options.DesignLock) : false

    useEffect(() => {
        if (designLock) {
            if (designLock.Default) {
                const totalWidth = LeftCalc(ColumnDesigns, designIndex)
                setColumnStyle({
                    position: "sticky",
                    left: totalWidth + "px",
                    background: "#002b45",
                    width: designLock.Width,
                    zIndex: ColumnDesigns.length - designIndex
                })
            }
        }
    }, [])
    return (

        <th
            data-field={dataId}
            data-lock={designLock.Default ? "on" : "off"}
            onClick={(event) => onHeaderCellClick(event)}
            className="JustStaticTable-HeaderCell"
            style={columnnStyle}
        >
            <span id="HeaderCell-Text">{ColumnDesign.Text}</span>
        </th>
    )
}
export default HeaderCell;
HeaderCell.propTypes = {
    ColumnDesign: PropTypes.object,
    ColumnDesigns: PropTypes.array,
    designIndex: PropTypes.number,
}
HeaderCell.defaultProps = {
    ColumnDesign: {},
    ColumnDesigns: [],
    designIndex: 0,
}