import moment from 'moment';
import 'moment/locale/tr'
import 'moment/locale/en-gb'
export const epochToShortDate = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('DD-MM-YYYY HH:mm');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DD-MM-YYYY');
    }
}
export const epochToDateTime = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('DD-MM-YYYY HH:mm');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DD-MM-YYYY HH:mm');
    }
}
export const epochToDateTimeNoFormat = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('DDMMYYYY HH:mm');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DDMMYYYY HH:mm')
    }
}
export const epochToMonthAndYear = (epochTime, Utc, Language) => {
    if (!epochTime) return ""
    moment.locale(Language === "eng" ? "en" : Language)
    if (Utc) {
        return moment(epochTime * 1000).format('MMMM YYYY');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('MMMM YYYY');

    }
}