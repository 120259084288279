import React, { useEffect, useState } from 'react';
import DeclarationTable from './DeclarationTable/DeclarationTable'
import VoyageRouteTable from './VoyageRouteTable/VoyageRouteTable'
import './Declaration.scss';
import ModalController from './DeclarationModalController'
import { clearDeclarationError, getDeclarations, setFirstLoad, UpdateRefreshTime } from '../../store/action/declarationAction'
import { connect } from 'react-redux';
import { ErrorModal } from '../CommonUI/Modals/ErrorModal';
import ProjectConfig from '../../config/config';
import Breadcrumb from '../CommonUI/Breadcrumb/Breadcrumb'
import history from '../../core/history';
import { Button } from '@dfds-ui/react-components';

// import {DeclarationLanguage} from '../../config/language/language'
const language = "en"

const Declaration = (props) => {
    const { ErrorMessage, ErrorTitle, FirstLoad, ErrorCode, VoyageId,
        clearDeclarationError, getDeclarations, ServiceConnection,
        WaitForRequest, RefreshTime,
        ChangePage, setFirstLoad, UpdateRefreshTime } = props;
    const BreadcrumbOptions = [
        {
            Pagename: "Voyage",
            onClick: () => { history.push("/voyage") }
        },
        {
            Pagename: "Declaration",
            Active: true
        }
    ]
    useEffect(() => {
        if (FirstLoad) {
            getDeclarations(VoyageId)
            UpdateRefreshTime()
        }
    }, [])
    return (
        <>
            <Breadcrumb Options={BreadcrumbOptions} Seperator={'>'} className={""} />

            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ErrorTitle={ErrorTitle}
                    ErrorMessage={ErrorMessage}
                    OnClose={() => {
                        if (ChangePage) {
                            setFirstLoad(true)
                            history.push("/voyage")
                        }
                        clearDeclarationError()
                    }}
                />
            }
            <div className="RefreshButtonContainer">
                <Button id="refreshDateButton"
                    onClick={() => {
                        getDeclarations(VoyageId)
                        UpdateRefreshTime()
                    }}>
                    Refresh
                </Button>
                <span id="refreshDateText">{`Last refresh time : ${RefreshTime}`}</span>

            </div>

            <ModalController />
            <div className="DeclarationContainer">
                <div className="pageHeader">
                    <h4 id="VesselName">
                        {localStorage.getItem(`${ProjectConfig.projectName}_Vesselname`)}
                        {
                            !WaitForRequest && ServiceConnection &&
                            <Button
                                onClick={() => history.push("/details")} id="detailsButton">Details</Button>
                        }
                    </h4>
                    <h4 id="Custom">
                        {localStorage.getItem(`${ProjectConfig.projectName}_Portname`) + " Custom"}
                    </h4>
                </div>
                <div className="voyageRouteTableContainer">
                    <VoyageRouteTable />
                </div>
                <div className="declarationTableContainer">
                    <DeclarationTable />
                </div>
            </div>
        </>
    );
}
const mapStateToProps = state => {
    return {
        ErrorMessage: state.declaration.Error.ErrorMessage,
        ErrorTitle: state.declaration.Error.ErrorTitle,
        ErrorCode: state.declaration.Error.ErrorCode,
        ChangePage: state.declaration.Error.ChangePage,
        FirstLoad: state.declaration.FirstLoad,
        VoyageId: state.declaration.VoyageId,
        WaitForRequest: state.declaration.WaitForRequest,
        ServiceConnection: state.declaration.ServiceConnection,
        RefreshTime: state.declaration.RefreshTime
    }
}
export default connect(mapStateToProps, { clearDeclarationError, getDeclarations, setFirstLoad, UpdateRefreshTime })(Declaration);