import React from 'react'
import SpinDiv from '../../CommonUI/Spindiv/Spindiv'
import NoVoyage from '../../CommonUI/TableError/TableError';
import NetworkError from '../../CommonUI/TableError/TableError';
import { AlertWarning } from '@dfds-ui/icons';

const TableErrorComponents = (NotFound,NoConnection,WaitForRequest,NotFoundMessage,NoConnectionMessage) => {
    const NoConnectionIco = <AlertWarning className="searchLogo" width="50px" height="50px" />
    const Components = [
        {
            show : NotFound,
            component : <NoVoyage key={1} ShowLogo={true} Message={NotFoundMessage} />
        },
        {
            show : NoConnection,
            component : <NetworkError key={2} Logo={NoConnectionIco} ShowLogo={true} Message={NoConnectionMessage} />
        },
        {
            show : WaitForRequest,
            component : <SpinDiv key={3} waitForRequest={WaitForRequest} ShowLogo={false} size={50} />
        },
    ]
    return Components;
}
export default TableErrorComponents;