const LeftCalculator = (ColumnDesigns, designIndex) => {
    let totalWidth = 0;
    for (var index = (designIndex - 1); index >= 0; index--) {
        const prevColumn = ColumnDesigns[index]
        const prevDesignLock = prevColumn.Options ?
            (prevColumn.Options.DesignLock ?
                prevColumn.Options.DesignLock : null) : null
        const prevWidth = prevDesignLock ? prevDesignLock.Width : 0
        const prevWidthNumber = (prevWidth === 0) ? 0 : prevWidth.split("px")[0]
        totalWidth = totalWidth + parseInt(prevWidthNumber)
    }
    return totalWidth - 1
}
export default LeftCalculator;